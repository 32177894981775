import React, { CSSProperties, useState, useEffect } from "react";
import {
    IonModal,
    IonRow,
    IonCol,
    IonButton,
    IonIcon,
    IonList,
    IonItem,
    IonInput,
    IonLabel,
    IonToggle,
    IonTextarea,
    IonRippleEffect,
} from "@ionic/react";
import { close } from "ionicons/icons";
import { IStory, useStoryUpdateMutation, IImage } from "../types/graphql";
import ImageEditor from "./image-editor";
import SectionSelector from "./section-selector";

interface IStoryEditorProps {
    onClose: Function;
    open: boolean;
    story: IStory | null;
    style?: CSSProperties;
}

const StoryEditor: React.FC<IStoryEditorProps> = ({ story, onClose, open, style = {} }) => {
    const [updateStory] = useStoryUpdateMutation();
    const [storyUpdate, setStoryUpdate] = useState<IStory>({} as IStory);
    const [showImageEdit, setShowImageEdit] = useState(false);

    useEffect(() => {
        setStoryUpdate({
            key: story?.key,
            title: story?.title,
            section: story?.section,
            image: story?.image,
            summary: story?.summary,
            url: story?.url,
            enabled: story?.enabled,
        } as IStory);
    }, [story]);

    const onSave = () => {
        updateStory({
            variables: {
                id: story?._id,
                input: { ...storyUpdate, image: storyUpdate.image?._id, locked: true },
            },
            optimisticResponse: {
                __typename: "Mutation",
                storyUpdate: {
                    ...story,
                    ...storyUpdate,
                }
            }
        });
        onClose();
    };

    return (
        <>
            <IonModal
                isOpen={open}
                backdropDismiss={false}
                onDidDismiss={() => onClose()}
                cssClass="ion-modal-scrollable"
            >
                <IonRow className="ion-align-items-center ion-padding-start">
                    <IonCol>
                        <h5>Editar historia</h5>
                    </IonCol>
                    <IonCol className="ion-text-end">
                        <IonButton fill="clear" onClick={onClose as any}>
                            <IonIcon slot="icon-only" icon={close} />
                        </IonButton>
                    </IonCol>
                </IonRow>
                <IonList lines="none" style={{ padding: 32 }}>
                    {storyUpdate?.image && (
                        <div
                            className="ion-activatable ripple-parent"
                            style={{
                                textAlign: "center",
                                margin: "10px auto 10px auto",
                                maxWidth: 300,
                                cursor: "pointer",
                            }}
                            onClick={() => setShowImageEdit(true)}
                        >
                            <img
                                style={{ borderRadius: 10 }}
                                src={storyUpdate?.image?.url as string}
                                alt={storyUpdate?.title as string}
                            />
                            <IonRippleEffect></IonRippleEffect>
                        </div>
                    )}
                    <IonItem>
                        <IonLabel position="stacked">ID</IonLabel>
                        <IonInput value={story?._id} disabled />
                    </IonItem>
                    <IonItem>
                        <IonLabel position="stacked">Titulo</IonLabel>
                        <IonTextarea
                            placeholder="Titulo"
                            rows={3}
                            value={storyUpdate.title}
                            onIonChange={(e) =>
                                setStoryUpdate({ ...storyUpdate, title: e.detail.value! })
                            }
                        />
                    </IonItem>
                    <SectionSelector
                        section={storyUpdate.section || "news"}
                        onChange={(value: string) => setStoryUpdate({ ...storyUpdate, section: value as any })}
                    />
                    <IonItem>
                        <IonLabel position="stacked">Sumario</IonLabel>
                        <IonTextarea
                            placeholder="Sumario"
                            rows={5}
                            value={storyUpdate.summary}
                            onIonChange={(e) =>
                                setStoryUpdate({ ...storyUpdate, summary: e.detail.value! })
                            }
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel position="stacked">Direccion</IonLabel>
                        <IonInput
                            placeholder="Direccion"
                            type="url"
                            value={storyUpdate.url}
                            onIonChange={(e) =>
                                setStoryUpdate({ ...storyUpdate, url: e.detail.value! })
                            }
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel>Habilitado</IonLabel>
                        <IonToggle
                            checked={storyUpdate.enabled as boolean}
                            onIonChange={(e) =>
                                setStoryUpdate({ ...storyUpdate, enabled: e.detail.checked! })
                            }
                        />
                    </IonItem>
                </IonList>
                <div className="ion-padding ion-text-end">
                    <IonButton
                        style={{ marginRight: 32 }}
                        onClick={() => {
                            onSave();
                        }}
                    >
                        Salvar
                    </IonButton>
                </div>
            </IonModal>
            <ImageEditor
                size={230}
                open={showImageEdit}
                onClose={() => setShowImageEdit(false)}
                onSave={(image: IImage) => {
                    setStoryUpdate({ ...storyUpdate, image });
                    setShowImageEdit(false);
                }}
            />
        </>
    );
};

export default StoryEditor;
