import moment from "moment";
import React, { useState } from "react";
import {
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonCol,
    IonRow,
    IonList,
    IonItem,
    IonLabel,
    IonText,
    IonAvatar,
    IonButton,
    IonSpinner,
    IonIcon,
} from "@ionic/react";
import { reload, eyeOffOutline, flashOffOutline } from "ionicons/icons";
import { useStatsBySourceLastStoriesQuery } from "../types/graphql";

const LIMIT = 10;

const StatsBySourceLastStories: React.FC = () => {
    const [limit, setLimit] = useState(LIMIT);
    const { data, loading, refetch } = useStatsBySourceLastStoriesQuery({
        notifyOnNetworkStatusChange: true,
    });
    const statsBySource = [...data?.statsBySourceLastStories || []].sort((a: any, b: any) => moment(b?.lastStory.date).isAfter(a?.lastStory.date) ? -1 : 1);
    return (
        <IonCard>
            {loading ? (
                <IonCardContent class="card-content-loading-large">
                    <IonSpinner />
                </IonCardContent>
            ) : (
                    <>
                        <IonCardHeader>
                            <IonCardTitle>
                                <IonRow >
                                    <IonCol className="ion-no-padding ion-align-self-center">
                                        Ultima Historia por Fuente
                                </IonCol>
                                    <IonCol size="auto" className="ion-no-padding ion-text-end">
                                        <IonButton
                                            fill="clear"
                                            size="small"
                                            onClick={() => refetch()}
                                        >
                                            <IonIcon slot="icon-only" icon={reload} />
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                            </IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent>
                            <IonList>
                                {statsBySource
                                    ?.map(({ source, lastStory }: any) => (
                                        <IonItem
                                            key={source._id}
                                            lines="none"
                                            detail={false}
                                            routerLink={"/stories?source=" + source._id}
                                        >
                                            <IonAvatar slot="start">
                                                {source?.image && (
                                                    <img
                                                        src={source?.image?.url as string}
                                                        alt={source?.title as string}
                                                    />
                                                )}
                                            </IonAvatar>
                                            <IonLabel className="ion-text-wrap">
                                                <h3>
                                                    {source?.name}
                                                </h3>
                                                <IonText style={{
                                                    fontSize: 14,
                                                    color: moment(lastStory.date).isBefore(moment().subtract(1, "months")) ?
                                                        "red" : moment(lastStory.date).isBefore(moment().subtract(2, "weeks")) ?
                                                            "orange" : "black"
                                                }}>
                                                    {moment(lastStory?.date).format("LLL")}
                                                </IonText>
                                            </IonLabel>
                                            <div slot="end" >
                                                {!source?.enabled && (
                                                    <IonIcon
                                                        style={{ color: "red" }}
                                                        icon={eyeOffOutline}
                                                        class="ion-margin-end"
                                                    />
                                                )}
                                                {!source?.scraperEnabled && (
                                                    <IonIcon
                                                        style={{ color: "orange" }}
                                                        icon={flashOffOutline}
                                                        class="ion-margin-end"
                                                    />
                                                )}
                                                <span style={{ textTransform: "capitalize" }}>{source?.interval}</span>
                                            </div>
                                        </IonItem>
                                    ))
                                    .slice(0, limit)}
                                {statsBySource.length > LIMIT && (
                                    <div className="ion-margin-top ion-text-center">
                                        <IonButton
                                            color="dark"
                                            fill="outline"
                                            onClick={() => (limit === LIMIT ? setLimit(100) : setLimit(LIMIT))}
                                        >
                                            Mostrar {limit === LIMIT ? "Todas" : "Menos"}
                                        </IonButton>
                                    </div>
                                )}
                            </IonList>
                        </IonCardContent>
                    </>
                )}
        </IonCard>
    );
};

export default StatsBySourceLastStories;
