import React, { CSSProperties } from "react";
import { IonButton } from "@ionic/react";

interface FabTextButtonProps {
    text: string;
    style?: CSSProperties;
    onClick: Function;
}

const FabTextButton: React.FC<FabTextButtonProps> = ({ text, style = {}, onClick }) => {
    return (
        <IonButton
            id="fab-text-button"
            style={style}
            size="large"
            shape="round"
            onClick={() => onClick()}
        >
            {text}
        </IonButton>
    );
};

export default FabTextButton;
