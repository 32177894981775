import moment from "moment";
import React, { useMemo, useState } from "react";
import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonMenuButton,
    IonProgressBar,
    IonButton,
    IonSpinner,
    IonPopover,
    IonList,
    IonItem,
    IonIcon,
    IonAlert,
    IonThumbnail,
    IonRippleEffect,
    IonSelect,
    IonSelectOption,
} from "@ionic/react";
import {
    useStoriesQuery,
    IStory,
    IHeadline,
    useSourceQuery,
    useSectionPreviewMutation,
    useStoriesClassifySectionMutation
} from "../types/graphql";
import StoryItem from "../components/story-item";
import HeadlineEditor from "../components/headline-editor";
import StoryEditor from "../components/story-editor";
import { searchOutline, closeOutline } from "ionicons/icons";
import { useLocation, useHistory } from "react-router";
import { SECTIONS_MAPPING } from "../utils/sections";

interface IStoriesSearchProps {
    search: string;
    setSearch: (search: string) => void;
}

const StoriesSearch: React.FC<IStoriesSearchProps> = ({ search, setSearch }) => {
    if (!search) {
        return null;
    }
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                margin: 20,
            }}
        >
            <div>
                Resultados para <b>{search}</b>
            </div>
            <IonButton fill="clear" onClick={() => setSearch("")}>
                <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
        </div>
    );
};

interface IStoriesSourceProps {
    sourceId: string | null;
}

const StoriesSource: React.FC<IStoriesSourceProps> = ({ sourceId }) => {
    const history = useHistory();
    const { data } = useSourceQuery({
        variables: {
            id: sourceId,
        },
        skip: !sourceId,
    });
    if (!sourceId || !data?.source) {
        return null;
    }
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                margin: 20,
            }}
        >
            <div
                className="ion-activatable ripple-parent"
                onClick={() => {
                    history.replace("/stories");
                }}
            >
                <IonThumbnail style={{ margin: "auto" }}>
                    {data.source?.image && (
                        <img
                            src={data.source?.image?.url as string}
                            alt={data.source?.name as string}
                        />
                    )}
                </IonThumbnail>
                <h3>{data.source?.name}</h3>
                <IonRippleEffect></IonRippleEffect>
            </div>
        </div>
    );
};

const StoriesPage: React.FC = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [selected, setSelected] = useState<IStory | null>(null);
    const [showMenu, setShowMenu] = useState<Event | undefined>(undefined);
    const [showEdit, setShowEdit] = useState(false);
    const [showHeadline, setShowHeadline] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [search, setSearch] = useState<string>("");
    const [section, setSection] = useState<string>("news");
    const [previewSection] = useSectionPreviewMutation();
    const [storiesClassifySection] = useStoriesClassifySectionMutation();
    const [showSectionClassify, setShowSectionClassify] = useState(false);
    const { data, loading, fetchMore } = useStoriesQuery({
        variables: {
            search,
            ignore_enabled: true,
            sources_in: searchParams.get("source") ? [searchParams.get("source")] : undefined,
            section: section !== 'news' ? section : undefined,
        },
    });
    const [pagination, setPagination] = useState<{ loading: boolean; completed: boolean }>({
        loading: false,
        completed: false,
    });
    const lastStoryDate = useMemo<Date | null>(
        () => (data?.stories?.length ? data.stories[data.stories.length - 1]?.date : null),
        [data]
    );
    const areMoreStories = useMemo<boolean>(
        () => !!data?.stories?.length && data.stories.length > 19,
        [data]
    );
    const onFetchMore = () => {
        fetchMore({
            variables: {
                date_lt: lastStoryDate,
            },
            updateQuery: (prev: any, { fetchMoreResult }: any) => {
                setPagination({
                    loading: false,
                    completed:
                        !fetchMoreResult?.stories?.length || fetchMoreResult.stories.length < 20,
                });
                if (!fetchMoreResult) return prev;
                return Object.assign({}, prev, {
                    stories: [...prev.stories, ...fetchMoreResult.stories],
                });
            },
        } as any);
        setPagination({ loading: true, completed: false });
    };
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Historias</IonTitle>
                    <IonButtons slot="end">
                        <IonSelect
                            value={section}
                            okText="Ok"
                            cancelText="Cancel"
                            onIonChange={(e) => setSection(e.detail.value!)}
                        >
                            {Object.keys(SECTIONS_MAPPING).map(key => <IonSelectOption value={key}>{SECTIONS_MAPPING[key]}</IonSelectOption>)}
                        </IonSelect>
                        <IonButton fill="clear" onClick={() => setShowSearch(true)}>
                            <IonIcon slot="icon-only" icon={searchOutline} />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {loading ? (
                    <IonProgressBar type="indeterminate" />
                ) : (
                        <>
                            <StoriesSearch search={search} setSearch={setSearch} />
                            <StoriesSource sourceId={searchParams.get("source")} />
                            <div
                                className="ion-padding-top"
                                style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
                            >
                                {data?.stories?.map((story) => (
                                    <StoryItem
                                        key={story?._id}
                                        story={story as IStory}
                                        onClickMenu={(event: any) => {
                                            setShowMenu(event.nativeEvent);
                                            setSelected(story as IStory);
                                        }}
                                    />
                                ))}
                            </div>
                            <div
                                className="ion-padding-top"
                                style={{ display: "flex", justifyContent: "center" }}
                            >
                                {lastStoryDate && areMoreStories && !pagination.completed && (
                                    <div style={{ textAlign: "center", marginBottom: 64 }}>
                                        {pagination.loading ? (
                                            <IonSpinner style={{ height: 40 }} />
                                        ) : (
                                                <IonButton
                                                    color="dark"
                                                    fill="outline"
                                                    onClick={() => onFetchMore()}
                                                >
                                                    Mostrar Más
                                                </IonButton>
                                            )}
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                <IonPopover
                    event={showMenu}
                    isOpen={!!showMenu}
                    onDidDismiss={(e) => setShowMenu(undefined)}
                >
                    <IonList lines="none">
                        <IonItem
                            button
                            detail={false}
                            onClick={() => {
                                setShowMenu(undefined);
                                window.open(selected?.url as string, "_blank");
                            }}
                        >
                            Ver
                        </IonItem>
                        <IonItem
                            button
                            detail={false}
                            onClick={() => {
                                setShowMenu(undefined);
                                setShowHeadline(true);
                            }}
                        >
                            Destacar
                        </IonItem>
                        <IonList lines="none">
                            <IonItem
                                button
                                detail={false}
                                onClick={() => {
                                    setShowMenu(undefined);
                                    setShowEdit(true);
                                }}
                            >
                                Editar
                            </IonItem>
                        </IonList>
                        <IonList lines="none" style={{ display: 'none' }}>
                            <IonItem
                                button
                                detail={false}
                                onClick={async () => {
                                    setShowMenu(undefined);
                                    const result = await previewSection({ variables: { text: `${selected?.title} ${selected?.summary}` } });
                                    let resultString = "";
                                    result.data?.sectionPreview.forEach((e: any) => {
                                        resultString = resultString + e.label + " - " + e.value + "\n";
                                    });
                                    alert(resultString);
                                }}
                            >
                                Preview de Sección
                            </IonItem>
                        </IonList>
                    </IonList>
                </IonPopover>
                <IonAlert
                    cssClass="ion-alert-edit"
                    isOpen={showSearch}
                    onDidDismiss={() => setShowSearch(false)}
                    header={"Buscar historia"}
                    inputs={[
                        {
                            name: "search",
                            type: "text",
                            placeholder: "Buscar",
                        },
                    ]}
                    buttons={[
                        {
                            text: "Cancelar",
                            role: "cancel",
                            cssClass: "secondary",
                        },
                        {
                            text: "Buscar",
                            handler: ({ search }) => {
                                if (search) {
                                    setSearch(search);
                                }
                            },
                        },
                    ]}
                />
                <IonAlert
                    isOpen={showSectionClassify}
                    onDidDismiss={() => setShowSectionClassify(false)}
                    header={"Sync Secciones"}
                    inputs={[
                        {
                            name: "date_gt",
                            type: "date",
                            placeholder: "Desde",
                        },
                        {
                            name: "date_lt",
                            type: "date",
                            placeholder: "Hasta",
                        },
                    ]}
                    buttons={[
                        {
                            text: "Cancelar",
                            role: "cancel",
                            cssClass: "secondary",
                        },
                        {
                            text: "Sincronizar",
                            handler: ({ date_gt, date_lt }) => {
                                storiesClassifySection({
                                    variables: {
                                        date_gt: date_gt ? moment(date_gt).toDate() : moment().subtract(1, 'day').toDate(),
                                        date_lt: date_lt ? moment(date_lt).toDate() : undefined,
                                    }
                                });
                            },
                        },
                    ]}
                />
                <HeadlineEditor
                    headline={{ story: selected } as IHeadline}
                    open={showHeadline}
                    onClose={() => setShowHeadline(false)}
                />
                <StoryEditor story={selected} open={showEdit} onClose={() => setShowEdit(false)} />
            </IonContent>
        </IonPage>
    );
};

export default StoriesPage;
