import moment from "moment";
import React, { useState } from "react";
import {
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonCol,
    IonRow,
    IonLabel,
    IonSegment,
    IonSegmentButton,
    IonButton,
    IonSpinner,
    IonIcon,
} from "@ionic/react";
import { reload } from "ionicons/icons";
import { Bar } from "react-chartjs-2";
import { useStatsTimelineQuery } from "../types/graphql";

const StatsTimeline: React.FC = () => {
    const [date] = useState(new Date());
    const [interval, setInterval] = useState("day");
    const { data, loading, refetch } = useStatsTimelineQuery({
        variables: {
            date_gt: moment(date).subtract(1, interval as any),
            group_by_hours: interval === "day",
        },
        notifyOnNetworkStatusChange: true,
    });

    const backgroundColor = "#eb445a";

    const datasets = {
        datasets: [
            {
                label: "Historias",
                backgroundColor,
                data: [
                    ...(data?.statsTimeline?.map((item: any) => ({
                        x: item.date,
                        y: item.count,
                    })) || []),
                    {
                        x: date,
                        y: 0,
                    },
                ],
            },
        ],
    };

    const options = {
        maintainAspectRatio: false,
        legend: {
            display: false,
        },
        scales: {
            xAxes: [
                {
                    type: "time",
                    time: {
                        unit: interval === "day" ? "hour" : "day",
                    },
                },
            ],
        },
        tooltips: {
            callbacks: {
                title: function (tooltipItem: any, data: any) {
                    return interval === "day"
                        ? moment(tooltipItem[0].label).format("lll")
                        : moment(tooltipItem[0].label).utc().format("ll");
                },
            },
        },
    };

    return (
        <IonCard>
            {loading ? (
                <IonCardContent class="card-content-loading-large">
                    <IonSpinner />
                </IonCardContent>
            ) : (
                    <>
                        <IonCardHeader>
                            <IonCardTitle>
                                <IonRow className="ion-margin-bottom">
                                    <IonCol className="ion-no-padding ion-align-self-center">
                                        Historias
                                </IonCol>
                                    <IonCol size="auto" className="ion-no-padding ion-text-end">
                                        <IonButton
                                            fill="clear"
                                            size="small"
                                            onClick={() => refetch()}
                                        >
                                            <IonIcon slot="icon-only" icon={reload} />
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                                <IonSegment
                                    value={interval}
                                    onIonChange={(e) => setInterval(e.detail.value as string)}
                                >
                                    <IonSegmentButton value="day">
                                        <IonLabel>Último Día</IonLabel>
                                    </IonSegmentButton>
                                    <IonSegmentButton value="week">
                                        <IonLabel>Última Semana</IonLabel>
                                    </IonSegmentButton>
                                    <IonSegmentButton value="month">
                                        <IonLabel>Último Mes</IonLabel>
                                    </IonSegmentButton>
                                </IonSegment>
                            </IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent style={{ height: 420 }}>
                            <Bar data={datasets} options={options} />
                        </IonCardContent>
                    </>
                )}
        </IonCard>
    );
};

export default StatsTimeline;
