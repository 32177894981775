import React, { createContext, ReactNode, useContext } from "react";
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/analytics";
import "firebase/messaging";

const config = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

interface IFirebase {
    app: firebase.app.App;
    auth: firebase.auth.Auth;
    firestore: firebase.firestore.Firestore;
    functions: firebase.functions.Functions;
}

const FirebaseContext = createContext<IFirebase>({} as IFirebase);

export const useFirebase = () => useContext(FirebaseContext);

export const FirebaseProvider = ({ children }: { children: ReactNode }) => {
    const app = !firebase.apps.length ? firebase.initializeApp(config) : firebase.app();

    const auth = app.auth();
    const firestore = app.firestore();
    const functions = app.functions();

    let messaging: firebase.messaging.Messaging | null = null;

    const value = {
        app: app,
        auth,
        firestore,
        functions,
        messaging,
    };
    return <FirebaseContext.Provider value={value}>{children}</FirebaseContext.Provider>;
};
