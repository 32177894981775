import React, { useMemo } from "react";
import { IonBadge } from "@ionic/react";
import { SECTIONS_MAPPING } from "../utils/sections";

interface ISectionBadgeProps {
    section: string;
}

const SectionBadge: React.FC<ISectionBadgeProps> = ({ section }) => {
    const color = useMemo(() => {
        switch (section) {
            case "science":
                return 'brown';
            case "sports":
                return 'blue';
            case "culture":
                return 'red';
            case "economy":
                return 'green';
            case "health":
                return 'cyan';
        }
        return 'black';
    }, [section])
    return (
        <IonBadge style={{ backgroundColor: color }}>{SECTIONS_MAPPING[section || 'news']}</IonBadge>
    );
};

export default SectionBadge;
