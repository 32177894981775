import React, { useState } from "react";
import {
    IonCard,
    IonCardContent,
    IonButton,
    IonIcon,
    IonPopover,
    IonList,
    IonItem,
    IonAlert,
    IonSpinner,
} from "@ionic/react";
import { ellipsisVertical } from "ionicons/icons";
import { useSettingUpdateMutation, SettingsDocument } from "../types/graphql";

interface IBanner {
    title: string;
    url: string;
}

interface IBannerEditorProps {
    banner: IBanner;
    loading: boolean;
}

const BannerEditor: React.FC<IBannerEditorProps | null> = ({ banner, loading }) => {
    const [showMenu, setShowMenu] = useState<Event | undefined>(undefined);
    const [showEdit, setShowEdit] = useState(false);
    const [updateSetting] = useSettingUpdateMutation();
    return (
        <>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <h3 style={{ margin: 0 }}>Banner</h3>
                <IonButton
                    slot="end"
                    fill="clear"
                    onClick={(event: any) => setShowMenu(event.nativeEvent)}
                >
                    <IonIcon slot="icon-only" icon={ellipsisVertical} />
                </IonButton>
            </div>
            <IonCard>
                {loading ? (
                    <IonCardContent class="card-content-loading">
                        <IonSpinner />
                    </IonCardContent>
                ) : (
                    <IonCardContent className="ion-margin-vertical">
                        <h2 className="ion-padding-bottom">{banner?.title || "-"}</h2>
                        <a
                            style={{ textDecoration: "none", fontWeight: "bold" }}
                            target="_blank"
                            href={banner?.url}
                            rel="noopener noreferrer"
                        >
                            {banner?.url || "-"}
                        </a>
                    </IonCardContent>
                )}
                <IonPopover
                    event={showMenu}
                    isOpen={!!showMenu}
                    onDidDismiss={(e) => setShowMenu(undefined)}
                >
                    <IonList lines="none">
                        <IonItem
                            button
                            detail={false}
                            onClick={() => {
                                setShowMenu(undefined);
                                setShowEdit(true);
                            }}
                        >
                            Editar
                        </IonItem>
                        <IonItem
                            button
                            detail={false}
                            onClick={() => {
                                setShowMenu(undefined);
                                updateSetting({
                                    variables: {
                                        input: {
                                            key: "banner",
                                            value: null,
                                        },
                                    },
                                });
                            }}
                        >
                            Borrar
                        </IonItem>
                    </IonList>
                </IonPopover>
                <IonAlert
                    cssClass="ion-alert-edit"
                    isOpen={showEdit}
                    onDidDismiss={() => setShowEdit(false)}
                    header={"Editar Banner"}
                    inputs={[
                        {
                            name: "title",
                            type: "text",
                            value: banner?.title,
                            placeholder: "Titulo",
                        },
                        {
                            name: "url",
                            type: "url",
                            value: banner?.url,
                            placeholder: "Url",
                        },
                    ]}
                    buttons={[
                        {
                            text: "Cancelar",
                            role: "cancel",
                            cssClass: "secondary",
                        },
                        {
                            text: "Salvar",
                            handler: ({ title, url }) => {
                                if (title && url) {
                                    updateSetting({
                                        variables: {
                                            input: {
                                                key: "banner",
                                                value: {
                                                    title,
                                                    url,
                                                },
                                            },
                                        },
                                        refetchQueries: [{ query: SettingsDocument }],
                                    });
                                }
                            },
                        },
                    ]}
                />
            </IonCard>
        </>
    );
};

export default BannerEditor;
