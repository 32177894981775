import React, { useState, useMemo } from "react";
import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonMenuButton,
    IonProgressBar,
    IonSpinner,
    IonButton,
    IonAlert,
} from "@ionic/react";
import {
    useCoveragesQuery,
    ICoverage,
    useCoverageCreateMutation,
    CoveragesDocument,
} from "../types/graphql";
import CoverageItem from "../components/coverage-item";
import FabTextButton from "../components/fab-text-button";

const CoveragesPage: React.FC = () => {
    const [showEditName, setShowEditName] = useState(false);
    const [createCoverage] = useCoverageCreateMutation();
    const { data, loading, fetchMore } = useCoveragesQuery();
    const [pagination, setPagination] = useState<{ loading: boolean; completed: boolean }>({
        loading: false,
        completed: false,
    });
    const lastDate = useMemo<Date | null>(
        () =>
            data?.coverages?.length ? data.coverages[data.coverages.length - 1]?.updatedAt : null,
        [data]
    );
    const areMore = useMemo<boolean>(
        () => !!data?.coverages?.length && data.coverages.length > 19,
        [data]
    );
    const onFetchMore = () => {
        fetchMore({
            variables: {
                updatedAt_lt: lastDate,
            },
            updateQuery: (prev: any, { fetchMoreResult }: any) => {
                setPagination({
                    loading: false,
                    completed:
                        !fetchMoreResult?.coverages?.length ||
                        fetchMoreResult.coverages.length < 20,
                });
                if (!fetchMoreResult) return prev;
                return Object.assign({}, prev, {
                    coverages: [...prev.coverages, ...fetchMoreResult.coverages],
                });
            },
        } as any);
        setPagination({ loading: true, completed: false });
    };
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Coberturas</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {loading ? (
                    <IonProgressBar type="indeterminate" />
                ) : (
                        <>
                            <div className="ion-padding">
                                {data?.coverages?.map((coverage) => (
                                    <CoverageItem
                                        key={coverage?._id}
                                        coverage={coverage as ICoverage}
                                    />
                                ))}
                            </div>
                            <div
                                className="ion-padding-top"
                                style={{ display: "flex", justifyContent: "center" }}
                            >
                                {lastDate && areMore && !pagination.completed && (
                                    <div style={{ textAlign: "center", marginBottom: 64 }}>
                                        {pagination.loading ? (
                                            <IonSpinner style={{ height: 40 }} />
                                        ) : (
                                                <IonButton
                                                    color="dark"
                                                    fill="outline"
                                                    onClick={() => onFetchMore()}
                                                >
                                                    Más Coberturas
                                                </IonButton>
                                            )}
                                    </div>
                                )}
                            </div>
                            <FabTextButton
                                style={{
                                    textTransform: "none",
                                    position: "fixed",
                                    bottom: 16,
                                    right: 16,
                                }}
                                text={"Nueva Cobertura"}
                                onClick={() => setShowEditName(true)}
                            />
                            <IonAlert
                                isOpen={showEditName}
                                onDidDismiss={() => setShowEditName(false)}
                                header={"Crear Cobertura"}
                                inputs={[
                                    {
                                        name: "name",
                                        type: "text",
                                        placeholder: "Nombre",
                                    },
                                ]}
                                buttons={[
                                    {
                                        text: "Cancelar",
                                        role: "cancel",
                                        cssClass: "secondary",
                                    },
                                    {
                                        text: "Crear",
                                        handler: ({ name }) => {
                                            if (name) {
                                                createCoverage({
                                                    variables: {
                                                        input: {
                                                            order: 10,
                                                            stories: [],
                                                            name,
                                                        },
                                                    },
                                                    refetchQueries: [
                                                        {
                                                            query: CoveragesDocument
                                                        },
                                                    ],
                                                });
                                            }
                                        },
                                    },
                                ]}
                            />
                        </>
                    )}
            </IonContent>
        </IonPage>
    );
};

export default CoveragesPage;
