import moment from "moment";
import React from "react";
import { IonItem, IonThumbnail, IonLabel, IonText } from "@ionic/react";
import { IStory } from "../types/graphql";

interface IStoryItemPros {
    story: IStory | null;
    onClick?: any;
}

const StoryItemThumbnail: React.FC<IStoryItemPros> = ({ story, onClick }) => {
    return (
        <IonItem className="transparent" button lines="none" detail={false} onClick={onClick}>
            <IonThumbnail slot="start">
                {story?.image && (
                    <img src={story?.image?.url as string} alt={story?.title as string} />
                )}
            </IonThumbnail>
            <IonLabel className="ion-text-wrap">
                <IonText>
                    <h3>{story?.source?.name}</h3>
                </IonText>
                <p>{story?.title}</p>
                <IonText>
                    <p>{moment(story?.date).format("LLL")}</p>
                </IonText>
            </IonLabel>
        </IonItem>
    );
};

export default StoryItemThumbnail;
