import moment from "moment";
import React, { CSSProperties, useState, useEffect } from "react";
import {
    IonModal,
    IonRow,
    IonCol,
    IonButton,
    IonIcon,
    IonList,
    IonItem,
    IonLabel,
    IonToggle,
    IonRange,
    IonSelect,
    IonSelectOption,
    IonImg,
} from "@ionic/react";
import { close } from "ionicons/icons";
import {
    IHeadline,
    useHeadlineUpdateMutation,
    useHeadlineCreateMutation,
    HeadlinesDocument,
} from "../types/graphql";

interface IHeadlineEditorProps {
    onClose: Function;
    open: boolean;
    headline: IHeadline | null;
    style?: CSSProperties;
}

const HeadlineEditor: React.FC<IHeadlineEditorProps> = ({
    headline,
    onClose,
    open,
    style = {},
}) => {
    const [updateHeadline] = useHeadlineUpdateMutation();
    const [createHeadline] = useHeadlineCreateMutation();
    const [headlineUpdate, setHeadlineUpdate] = useState<IHeadline>({} as IHeadline);
    const [expire, setExpire] = useState("");
    const [notify, setNotify] = useState(true);

    useEffect(() => {
        setHeadlineUpdate({
            ...(headline?._id
                ? { order: headline.order }
                : {
                      story: headline?.story?._id,
                      order: 10,
                      expireAt: moment().add(1, "day").toDate(),
                  }),
        } as IHeadline);
        setExpire(headline?._id ? "" : "24");
        setNotify(true);
    }, [headline]);

    return (
        <IonModal
            isOpen={open}
            backdropDismiss={false}
            onDidDismiss={() => onClose()}
            cssClass="ion-modal-scrollable"
        >
            <IonRow className="ion-align-items-center ion-padding-start">
                <IonCol>
                    <h5>{headline?._id ? "Editar" : "Crear"} destacada</h5>
                </IonCol>
                <IonCol className="ion-text-end">
                    <IonButton fill="clear" onClick={onClose as any}>
                        <IonIcon slot="icon-only" icon={close} />
                    </IonButton>
                </IonCol>
            </IonRow>
            <IonList lines="none" style={{ padding: 32 }}>
                {headline?.story?.image?.url ? (
                    <IonImg
                        style={{ width: 128, margin: "24px auto" }}
                        src={headline?.story?.image.url}
                    />
                ) : (
                    <div
                        style={{
                            margin: "auto",
                            width: 128,
                            height: 128,
                            backgroundColor: "#999",
                        }}
                    />
                )}
                <p className="ion-text-center" style={{ color: "#999", fontSize: 14 }}>
                    {headline?.story?.source?.name.toUpperCase()}
                </p>
                <h5 className="ion-text-center">{headline?.story?.title}</h5>
                <IonItem>
                    <IonLabel>Prioridad</IonLabel>
                    <IonRange
                        min={0}
                        max={10}
                        pin={true}
                        snaps={true}
                        value={headlineUpdate.order as number}
                        onIonChange={(e) =>
                            setHeadlineUpdate({ ...headlineUpdate, order: Number(e.detail.value!) })
                        }
                    >
                        <IonLabel slot="end">
                            <h3>{headlineUpdate.order}</h3>
                        </IonLabel>
                    </IonRange>
                </IonItem>
                <IonItem>
                    <IonLabel>Expirar en</IonLabel>
                    <IonSelect
                        value={expire}
                        placeholder="Selecciona"
                        onIonChange={(e) => {
                            setExpire(e.detail.value);
                            setHeadlineUpdate({
                                ...headlineUpdate,
                                expireAt: moment().add(Number(e.detail.value), "hours").toDate(),
                            });
                        }}
                    >
                        <IonSelectOption value="1">1 Hora</IonSelectOption>
                        <IonSelectOption value="6">6 Horas</IonSelectOption>
                        <IonSelectOption value="24">1 Día</IonSelectOption>
                        <IonSelectOption value="48">2 Días</IonSelectOption>
                        <IonSelectOption value="168">1 Semana</IonSelectOption>
                    </IonSelect>
                </IonItem>
                {!headline?._id && (
                    <IonItem>
                        <IonLabel>Notificar</IonLabel>
                        <IonToggle
                            checked={notify}
                            onIonChange={(e) => setNotify(e.detail.checked)}
                        />
                    </IonItem>
                )}
            </IonList>
            <div className="ion-padding ion-text-end">
                <IonButton
                    onClick={() => {
                        if (headline?._id) {
                            updateHeadline({
                                variables: {
                                    id: headline._id,
                                    input: headlineUpdate,
                                },
                                refetchQueries: [{ query: HeadlinesDocument }],
                            });
                        } else {
                            createHeadline({
                                variables: {
                                    input: headlineUpdate,
                                    notify,
                                },
                                refetchQueries: [{ query: HeadlinesDocument }],
                            });
                        }
                        onClose();
                    }}
                >
                    {headline?._id ? "Salvar" : "Crear"}
                </IonButton>
            </div>
        </IonModal>
    );
};

export default HeadlineEditor;
