import React, { useState, useMemo } from "react";
import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonMenuButton,
    IonSegment,
    IonProgressBar,
    IonSegmentButton,
    IonLabel,
    IonChip,
    IonIcon,
    IonAlert,
    IonButton,
    IonSpinner,
} from "@ionic/react";
import {
    useSectionsQuery, useSectionUpdateMutation, useSectionPreviewMutation
} from "../types/graphql";
import { SECTIONS_MAPPING } from "../utils/sections";
import { closeCircle } from "ionicons/icons";

const SectionsPage: React.FC = () => {
    const [showNew, setShowNew] = useState(false);
    const [showSectionPreview, setShowSectionPreview] = useState(false);
    const [showDelete, setShowDelete] = useState("");
    const [currentSection, setCurrentSection] = useState('culture');
    const { data, loading } = useSectionsQuery();
    const [updateSection] = useSectionUpdateMutation();
    const [previewSection, { loading: loadingPreview }] = useSectionPreviewMutation();
    const currentSectionData = useMemo(() =>
        data?.sections?.find(section => section?.key === currentSection)
        , [currentSection, data]);
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Secciones</IonTitle>
                    <IonButtons slot="end">
                        {loadingPreview ? <IonSpinner /> : <IonButton fill="clear" onClick={() => setShowSectionPreview(true)}>
                            Probar Clasificador
                        </IonButton>}
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {loading ? (
                    <IonProgressBar type="indeterminate" />
                ) : (
                        <div className="ion-padding">
                            <IonSegment value={currentSection} scrollable={true} onIonChange={e => setCurrentSection(e.detail.value as string)}>
                                {data?.sections?.map((section) => <IonSegmentButton key={section?.key as string} value={section?.key as string}>
                                    <IonLabel>{SECTIONS_MAPPING[section?.key as string]}</IonLabel>
                                </IonSegmentButton>)}
                            </IonSegment>
                            <div className="ion-padding">
                                {currentSectionData?.terms?.map(term => <IonChip key={term as string} onClick={() => setShowDelete(term as string)}>
                                    <IonLabel>{term}</IonLabel>
                                    <IonIcon icon={closeCircle} />
                                </IonChip>)}
                                <IonChip style={{
                                    backgroundColor: "black",
                                    color: "white"
                                }} onClick={() => setShowNew(true)}>
                                    <IonLabel>Nuevo Término</IonLabel>
                                </IonChip>
                            </div>
                        </div>
                    )}
                <IonAlert
                    isOpen={showNew}
                    onDidDismiss={() => setShowNew(false)}
                    header={"Nuevo Término"}
                    inputs={[
                        {
                            name: "name",
                            type: "text",
                            placeholder: "Término",
                        },
                    ]}
                    buttons={[
                        {
                            text: "Cancelar",
                            role: "cancel",
                            cssClass: "secondary",
                        },
                        {
                            text: "Crear",
                            handler: ({ name }) => {
                                if (name && !(currentSectionData?.terms && currentSectionData.terms.includes(name))) {
                                    updateSection({
                                        variables: {
                                            id: data?.sections?.find(section => section?.key === currentSection)?._id,
                                            input: {
                                                terms: [...currentSectionData?.terms as any, name]
                                            },
                                        },
                                        optimisticResponse: {
                                            __typename: "Mutation",
                                            sectionUpdate: {
                                                ...currentSectionData,
                                                terms: [...currentSectionData?.terms as any, name]
                                            } as any
                                        }
                                    });
                                }
                            },
                        },
                    ]}
                />
                <IonAlert
                    isOpen={!!showDelete}
                    onDidDismiss={() => setShowDelete('')}
                    header="Confirmación"
                    message={`Estas seguro de eliminar el término "${showDelete}" ?`}
                    buttons={[
                        {
                            text: 'No',
                            role: 'cancel',
                        },
                        {
                            text: 'Si',
                            handler: () => {
                                updateSection({
                                    variables: {
                                        id: data?.sections?.find(section => section?.key === currentSection)?._id,
                                        input: {
                                            terms: currentSectionData?.terms?.filter(e => e !== showDelete)
                                        },
                                    },
                                    optimisticResponse: {
                                        __typename: "Mutation",
                                        sectionUpdate: {
                                            ...currentSectionData,
                                            terms: currentSectionData?.terms?.filter(e => e !== showDelete)
                                        } as any
                                    }
                                });
                            }
                        }
                    ]}
                />
                <IonAlert
                    cssClass="ion-alert-edit"
                    isOpen={showSectionPreview}
                    onDidDismiss={() => setShowSectionPreview(false)}
                    header={"Probar classificador"}
                    inputs={[
                        {
                            name: "text",
                            type: "text",
                            placeholder: "Texto",
                        },
                    ]}
                    buttons={[
                        {
                            text: "Cancelar",
                            role: "cancel",
                            cssClass: "secondary",
                        },
                        {
                            text: "Probar",
                            handler: async ({ text }) => {
                                if (text) {
                                    const result = await previewSection({ variables: { text } });
                                    let resultString = "";
                                    result.data?.sectionPreview.forEach((e: any) => {
                                        resultString = resultString + e.label + " " + e.value + "\n";
                                    });
                                    alert(resultString);
                                }
                            },
                        },
                    ]}
                />
            </IonContent>
        </IonPage>
    );
};

export default SectionsPage;
