import React from "react";
import { IonItem, IonLabel, IonSelect, IonSelectOption } from "@ionic/react";
import { SECTIONS_MAPPING } from "../utils/sections";

interface ISectionSelectorProps {
    section: string;
    onChange?: any;
}

const SectionSelector: React.FC<ISectionSelectorProps> = ({ section, onChange }) => {
    return (
        <IonItem>
            <IonLabel>Sección</IonLabel>
            <IonSelect
                value={section}
                okText="Ok"
                cancelText="Cancel"
                onIonChange={(e) => onChange(e.detail.value!)}
            >
                {Object.keys(SECTIONS_MAPPING).map(key => <IonSelectOption key={key} value={key}>{SECTIONS_MAPPING[key]}</IonSelectOption>)}
            </IonSelect>
        </IonItem>
    );
};

export default SectionSelector;
