import React, { useState, useMemo } from "react";
import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonMenuButton,
    IonProgressBar,
    IonSpinner,
    IonButton,
} from "@ionic/react";
import { useHeadlinesQuery, IStory, IHeadline } from "../types/graphql";
import FabTextButton from "../components/fab-text-button";
import StoriesSelector from "../components/stories-selector";
import HeadlineEditor from "../components/headline-editor";
import HeadlineItem from "../components/headline-item";

const HeadlinesPage: React.FC = () => {
    const [showNewHeadline, setShowNewHeadline] = useState(undefined);
    const [showStorySelector, setShowStorySelector] = useState(false);
    const { data, loading, fetchMore } = useHeadlinesQuery();
    const [pagination, setPagination] = useState<{ loading: boolean; completed: boolean }>({
        loading: false,
        completed: false,
    });
    const lastDate = useMemo<Date | null>(
        () =>
            data?.headlines?.length ? data.headlines[data.headlines.length - 1]?.expireAt : null,
        [data]
    );
    const areMore = useMemo<boolean>(
        () => !!data?.headlines?.length && data.headlines.length > 19,
        [data]
    );
    const onFetchMore = () => {
        fetchMore({
            variables: {
                updatedAt_lt: lastDate,
            },
            updateQuery: (prev: any, { fetchMoreResult }: any) => {
                setPagination({
                    loading: false,
                    completed:
                        !fetchMoreResult?.headlines?.length ||
                        fetchMoreResult.headlines.length < 20,
                });
                if (!fetchMoreResult) return prev;
                return Object.assign({}, prev, {
                    headlines: [...prev.headlines, ...fetchMoreResult.headlines],
                });
            },
        } as any);
        setPagination({ loading: true, completed: false });
    };
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Destacadas</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {loading ? (
                    <IonProgressBar type="indeterminate" />
                ) : (
                    <>
                        <div
                            className="ion-padding-top"
                            style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
                        >
                            {data?.headlines?.map((headline) => (
                                <HeadlineItem
                                    key={headline?._id}
                                    headline={headline as IHeadline}
                                />
                            ))}
                        </div>
                        <div
                            className="ion-padding-top"
                            style={{ display: "flex", justifyContent: "center" }}
                        >
                            {lastDate && areMore && !pagination.completed && (
                                <div style={{ textAlign: "center", marginBottom: 64 }}>
                                    {pagination.loading ? (
                                        <IonSpinner style={{ height: 40 }} />
                                    ) : (
                                        <IonButton
                                            color="dark"
                                            fill="outline"
                                            onClick={() => onFetchMore()}
                                        >
                                            Más Destacados
                                        </IonButton>
                                    )}
                                </div>
                            )}
                        </div>
                        <FabTextButton
                            style={{
                                textTransform: "none",
                                position: "fixed",
                                bottom: 16,
                                right: 16,
                                zIndex: 1000,
                            }}
                            text={"Nueva Destacada"}
                            onClick={() => setShowStorySelector(true)}
                        />
                    </>
                )}
                {showStorySelector && (
                    <StoriesSelector
                        open={true}
                        onClose={() => setShowStorySelector(false)}
                        exclude={data?.headlines?.map((e) => e?.story?._id) || []}
                        onSelect={(story: IStory) => {
                            setShowStorySelector(false);
                            setShowNewHeadline(story as any);
                        }}
                    />
                )}
                <HeadlineEditor
                    headline={{ story: showNewHeadline } as IHeadline}
                    open={!!showNewHeadline}
                    onClose={() => setShowNewHeadline(undefined)}
                />
            </IonContent>
        </IonPage>
    );
};

export default HeadlinesPage;
