import React, { CSSProperties, useState, useEffect } from "react";
import {
    IonModal,
    IonRow,
    IonCol,
    IonButton,
    IonIcon,
    IonList,
    IonItem,
    IonInput,
    IonLabel,
    IonToggle,
    IonTextarea,
    IonItemDivider,
    IonSelect,
    IonSelectOption,
    IonAvatar,
    IonImg,
} from "@ionic/react";
import { close } from "ionicons/icons";
import {
    ISource,
    useSourceUpdateMutation,
    useSourceCreateMutation,
    SourcesDocument,
    IImage,
    useSourcePreviewMutation,
} from "../types/graphql";
import ImageEditor from "./image-editor";
import SourcePreview from "./source-preview";
import SectionSelector from "./section-selector";

interface ISourceEditorProps {
    onClose: Function;
    open: boolean;
    source: ISource | null;
    style?: CSSProperties;
}

const initialValues = {
    enabled: true,
    interval: "hourly",
    scraper: "standard",
    scraperEnabled: true,
    scraperSlice: 10,
};

const SourceEditor: React.FC<ISourceEditorProps> = ({ source, onClose, open, style = {} }) => {
    const [updateSource] = useSourceUpdateMutation();
    const [createSource] = useSourceCreateMutation();
    const [
        previewSource,
        { loading: previewLoading, data: previewData },
    ] = useSourcePreviewMutation();
    const [sourceUpdate, setSourceUpdate] = useState<ISource>({} as ISource);
    const [showImageEdit, setShowImageEdit] = useState(false);
    const [showImageStoryEdit, setShowImageStoryEdit] = useState(false);
    const [showPreview, setShowPreview] = useState(false);

    useEffect(() => {
        setSourceUpdate({
            ...(source?._id
                ? {
                    key: source.key,
                    name: source.name,
                    section: source.section,
                    image: source.image,
                    imageStory: source.imageStory,
                    description: source.description,
                    url: source.url,
                    contact: source.contact,
                    enabled: source.enabled,
                    feed: source.feed,
                    interval: source.interval,
                    scraper: source.scraper,
                    scraperEnabled: source.scraperEnabled,
                    scraperRegex: source.scraperRegex,
                    scraperSlice: source.scraperSlice,
                    scraperSkipMeta: source.scraperSkipMeta,
                    scraperUseBrowser: source.scraperUseBrowser,
                    government: source.government,
                }
                : initialValues),
        } as ISource);
    }, [source]);

    const onSave = () => {
        const input = { ...sourceUpdate, image: sourceUpdate.image?._id, imageStory: sourceUpdate.imageStory?._id };
        if (source?._id) {
            updateSource({
                variables: {
                    id: source?._id,
                    input,
                },
                optimisticResponse: {
                    __typename: "Mutation",
                    sourceUpdate: {
                        ...source,
                        ...sourceUpdate
                    }
                }
            });
        } else {
            createSource({
                variables: {
                    input,
                },
                refetchQueries: [{ query: SourcesDocument, variables: { ignore_enabled: true } }],
            });
        }
        onClose();
    };

    const onPreview = () => {
        previewSource({
            variables: {
                input: { ...sourceUpdate, image: sourceUpdate.image?._id },
            },
        });
        setShowPreview(true);
    };

    return (
        <>
            <IonModal
                isOpen={open}
                backdropDismiss={false}
                onDidDismiss={() => onClose()}
                cssClass="ion-modal-scrollable"
            >
                <IonRow className="ion-align-items-center ion-padding-start">
                    <IonCol>
                        <h5>{source?._id ? "Editar" : "Crear"} fuente</h5>
                    </IonCol>
                    <IonCol className="ion-text-end">
                        <IonButton fill="clear" onClick={onClose as any}>
                            <IonIcon slot="icon-only" icon={close} />
                        </IonButton>
                    </IonCol>
                </IonRow>
                <IonList lines="none" style={{ padding: 32 }}>
                    <IonItem>
                        <IonLabel position="stacked">Nombre</IonLabel>
                        <IonInput
                            placeholder="Nombre"
                            value={sourceUpdate.name}
                            onIonChange={(e) =>
                                setSourceUpdate({ ...sourceUpdate, name: e.detail.value! })
                            }
                        />
                    </IonItem>
                    <SectionSelector
                        section={sourceUpdate.section || "news"}
                        onChange={(value: string) => setSourceUpdate({ ...sourceUpdate, section: value as any })}
                    />
                    <IonItem>
                        <IonLabel position="stacked">Descripcion</IonLabel>
                        <IonTextarea
                            placeholder="Descripcion"
                            rows={3}
                            value={sourceUpdate.description}
                            onIonChange={(e) =>
                                setSourceUpdate({ ...sourceUpdate, description: e.detail.value! })
                            }
                        />
                    </IonItem>
                    <IonItem button onClick={() => setShowImageEdit(true)}>
                        <IonLabel className="ion-text-wrap">Imagen</IonLabel>
                        {sourceUpdate?.image ? (
                            <IonAvatar slot="end">
                                <img
                                    src={sourceUpdate?.image?.url as string}
                                    alt={sourceUpdate?.name as string}
                                />
                            </IonAvatar>
                        ) : (
                                <div
                                    style={{
                                        width: 40,
                                        height: 40,
                                        borderRadius: 20,
                                        backgroundColor: "#999",
                                    }}
                                ></div>
                            )}
                    </IonItem>
                    <IonItem>
                        <IonLabel position="stacked">Direccion</IonLabel>
                        <IonInput
                            placeholder="Direccion"
                            type="url"
                            value={sourceUpdate.url}
                            onIonChange={(e) =>
                                setSourceUpdate({ ...sourceUpdate, url: e.detail.value! })
                            }
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel position="stacked">Contacto</IonLabel>
                        <IonInput
                            placeholder="Contacto"
                            type="email"
                            value={sourceUpdate.contact}
                            onIonChange={(e) =>
                                setSourceUpdate({ ...sourceUpdate, contact: e.detail.value! })
                            }
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel>Habilitado</IonLabel>
                        <IonToggle
                            checked={sourceUpdate.enabled as boolean}
                            onIonChange={(e) =>
                                setSourceUpdate({ ...sourceUpdate, enabled: e.detail.checked! })
                            }
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel>Pertenece al Gobierno</IonLabel>
                        <IonToggle
                            checked={sourceUpdate.government as boolean}
                            onIonChange={(e) =>
                                setSourceUpdate({ ...sourceUpdate, government: e.detail.checked! })
                            }
                        />
                    </IonItem>
                    <IonItem button onClick={() => setShowImageStoryEdit(true)}>
                        <IonLabel className="ion-text-wrap">Imagen por defecto para Historias</IonLabel>
                        {sourceUpdate?.imageStory ? (
                            <IonImg style={{ width: 128, margin: "auto" }} src={sourceUpdate.imageStory.url} />
                        ) : (
                                <div
                                    style={{
                                        margin: "auto",
                                        width: 128,
                                        height: 128,
                                        backgroundColor: "#999",
                                    }}
                                />
                            )}
                    </IonItem>
                    <div className="ion-padding ion-text-end">
                        <IonButton
                            onClick={() => {
                                onSave();
                            }}
                        >
                            Salvar
                        </IonButton>
                    </div>
                    <IonItemDivider>
                        <IonLabel>Avanzado</IonLabel>
                    </IonItemDivider>
                    <IonItem>
                        <IonLabel position="stacked">Key</IonLabel>
                        <IonInput
                            placeholder="Key"
                            value={sourceUpdate.key}
                            onIonChange={(e) =>
                                setSourceUpdate({ ...sourceUpdate, key: e.detail.value! })
                            }
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel position="stacked">Feed</IonLabel>
                        <IonInput
                            placeholder="Feed"
                            type="url"
                            value={sourceUpdate.feed}
                            onIonChange={(e) =>
                                setSourceUpdate({ ...sourceUpdate, feed: e.detail.value! })
                            }
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel>Interval</IonLabel>
                        <IonSelect
                            value={sourceUpdate.interval}
                            okText="Ok"
                            cancelText="Cancel"
                            onIonChange={(e) =>
                                setSourceUpdate({ ...sourceUpdate, interval: e.detail.value! })
                            }
                        >
                            <IonSelectOption value="hourly">Hourly</IonSelectOption>
                            <IonSelectOption value="morning">Morning</IonSelectOption>
                            <IonSelectOption value="afternoon">Afternoon</IonSelectOption>
                            <IonSelectOption value="semiDaily">Semi Daily</IonSelectOption>
                            <IonSelectOption value="daily">Daily</IonSelectOption>
                        </IonSelect>
                    </IonItem>
                    <IonItem>
                        <IonLabel>Scraper</IonLabel>
                        <IonSelect
                            value={sourceUpdate.scraper}
                            okText="Ok"
                            cancelText="Cancel"
                            onIonChange={(e) =>
                                setSourceUpdate({ ...sourceUpdate, scraper: e.detail.value! })
                            }
                        >
                            <IonSelectOption value="standard">STANDARD</IonSelectOption>
                            <IonSelectOption value="feedly">FEEDLY</IonSelectOption>
                            <IonSelectOption value="bcube">BCUBE</IonSelectOption>
                            <IonSelectOption value="mrss">MRSS</IonSelectOption>
                            <IonSelectOption value="pangea">PANGEA</IonSelectOption>
                            <IonSelectOption value="cubitanow">*CUBITANOW</IonSelectOption>
                            <IonSelectOption value="diariodelasamericas">
                                *DIARIODELASAMERICAS
                            </IonSelectOption>
                            <IonSelectOption value="cubacute">*CUBACUTE</IonSelectOption>
                            <IonSelectOption value="elnuevoherald">*ELNUEVOHERALD</IonSelectOption>
                        </IonSelect>
                    </IonItem>
                    <IonItem>
                        <IonLabel>Scraper Enabled</IonLabel>
                        <IonToggle
                            checked={sourceUpdate.scraperEnabled as boolean}
                            onIonChange={(e) =>
                                setSourceUpdate({
                                    ...sourceUpdate,
                                    scraperEnabled: e.detail.checked!,
                                })
                            }
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel position="stacked">Scraper Regex</IonLabel>
                        <IonInput
                            placeholder="Scraper Regex"
                            type="url"
                            value={sourceUpdate.scraperRegex}
                            onIonChange={(e) =>
                                setSourceUpdate({ ...sourceUpdate, scraperRegex: e.detail.value! })
                            }
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel position="stacked">Scraper Slice</IonLabel>
                        <IonInput
                            type="number"
                            value={sourceUpdate.scraperSlice}
                            onIonChange={(e) =>
                                setSourceUpdate({
                                    ...sourceUpdate,
                                    scraperSlice: Number(e.detail.value!),
                                })
                            }
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel>Scraper Skip Meta</IonLabel>
                        <IonToggle
                            checked={sourceUpdate.scraperSkipMeta as boolean}
                            onIonChange={(e) =>
                                setSourceUpdate({
                                    ...sourceUpdate,
                                    scraperSkipMeta: e.detail.checked!,
                                })
                            }
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel>Scraper use Browser</IonLabel>
                        <IonToggle
                            checked={sourceUpdate.scraperUseBrowser as boolean}
                            onIonChange={(e) =>
                                setSourceUpdate({
                                    ...sourceUpdate,
                                    scraperUseBrowser: e.detail.checked!,
                                })
                            }
                        />
                    </IonItem>
                </IonList>
                <div className="ion-padding ion-text-end">
                    <IonButton
                        fill="outline"
                        style={{ marginRight: 16 }}
                        onClick={() => {
                            onPreview();
                        }}
                    >
                        Preview
                    </IonButton>
                    <IonButton
                        style={{ marginRight: 32 }}
                        onClick={() => {
                            onSave();
                        }}
                    >
                        Salvar
                    </IonButton>
                </div>
            </IonModal>
            <ImageEditor
                open={showImageEdit}
                onClose={() => setShowImageEdit(false)}
                onSave={(image: IImage) => {
                    setSourceUpdate({ ...sourceUpdate, image });
                    setShowImageEdit(false);
                }}
            />
            <ImageEditor
                size={128}
                open={showImageStoryEdit}
                onClose={() => setShowImageStoryEdit(false)}
                onSave={(imageStory: IImage) => {
                    setSourceUpdate({ ...sourceUpdate, imageStory });
                    setShowImageStoryEdit(false);
                }}
            />
            <SourcePreview
                loading={previewLoading}
                preview={previewData}
                open={showPreview}
                onClose={() => setShowPreview(false)}
            />
        </>
    );
};

export default SourceEditor;
