import React from "react";
import { IonCard, IonItem, IonAvatar, IonLabel, IonList, IonButton, IonIcon } from "@ionic/react";
import { ISource } from "../types/graphql";
import { ellipsisVertical, eyeOffOutline, flashOffOutline } from "ionicons/icons";
import SectionBadge from "./section-badge";

interface ISourceItemPros {
    source: ISource | null;
    onClickMenu?: any;
}

const SourceItem: React.FC<ISourceItemPros> = ({ source, onClickMenu }) => {
    return (
        <IonCard style={{ width: 320 }}>
            <IonList lines="none">
                <IonItem className="ion-margin-top">
                    <IonAvatar slot="start">
                        <img src={source?.image?.url as string} alt={source?.name as string} />
                    </IonAvatar>
                    <IonLabel className="ion-text-wrap">
                        {source?.name}
                        <div>
                            <SectionBadge section={source?.section as string} />
                        </div>
                    </IonLabel>
                    <IonButton slot="end" fill="clear" onClick={onClickMenu}>
                        <IonIcon slot="icon-only" icon={ellipsisVertical} />
                    </IonButton>
                </IonItem>
                <IonItem>
                    <p>{source?.description}</p>
                </IonItem>
                <IonItem>
                    <a href={"mailto:" + source?.contact}>{source?.contact}</a>
                </IonItem>
                <IonItem>
                    {!source?.enabled && (
                        <IonIcon
                            style={{ color: "red" }}
                            icon={eyeOffOutline}
                            class="ion-margin-end"
                        />
                    )}
                    {!source?.scraperEnabled && (
                        <IonIcon
                            style={{ color: "orange" }}
                            icon={flashOffOutline}
                            class="ion-margin-end"
                        />
                    )}
                </IonItem>
            </IonList>
        </IonCard>
    );
};

export default SourceItem;
