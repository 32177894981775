import React, { useState } from "react";
import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonMenuButton,
    IonItem,
    IonLabel,
    IonText,
    IonCard,
    IonList,
    IonButton,
    IonIcon,
    IonAlert,
} from "@ionic/react";
import { useSettingsQuery, useSettingUpdateMutation, SettingsDocument } from "../types/graphql";
import BannerEditor from "../components/banner-editor";
import { ellipsisVertical } from "ionicons/icons";

const settings = [
    {
        key: "feedly_access_token",
        name: "Feedly Key",
        readonly: false
    }
];

const SettingsPage: React.FC = () => {
    const { loading, data } = useSettingsQuery();
    const [updateSetting] = useSettingUpdateMutation();
    const [showEditKey, setShowEditKey] = useState("");
    const getSettingValue = (key: string) => data?.settings?.find((setting) => setting?.key === key)?.value;
    const getSettingName = (key: string) => settings.find(setting => setting.key === key)?.name;
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Ajustes</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent class="ion-padding">
                <BannerEditor
                    loading={loading}
                    banner={getSettingValue("banner")}
                />
                <div
                    style={{
                        marginTop: 32,
                    }}
                >
                    <h3 style={{ margin: "0 0 16px 0" }}>Ajustes</h3>
                    <IonCard>
                        <IonList lines="none">
                            {settings.map(setting => <IonItem key={setting.key}>
                                <IonLabel className="ion-text-wrap">
                                    <IonText>
                                        <h2>{setting.name}</h2>
                                    </IonText>
                                    <p>{getSettingValue(setting.key) || "-"}</p>
                                </IonLabel>
                                {
                                    !setting.readonly && <IonButton slot="end" fill="clear" onClick={() => setShowEditKey("feedly_access_token")}>
                                        <IonIcon slot="icon-only" icon={ellipsisVertical} />
                                    </IonButton>
                                }
                            </IonItem>)}
                        </IonList>
                    </IonCard>
                </div>
            </IonContent>
            <IonAlert
                cssClass="ion-alert-edit"
                isOpen={!!showEditKey}
                onDidDismiss={() => setShowEditKey("")}
                header={getSettingName(showEditKey)}
                inputs={[
                    {
                        name: "value",
                        type: "text",
                        placeholder: "Value",
                        value: getSettingValue(showEditKey)
                    },
                ]}
                buttons={[
                    {
                        text: "Cancelar",
                        role: "cancel",
                        cssClass: "secondary",
                    },
                    {
                        text: "Salvar",
                        handler: ({ value }) => {
                            updateSetting({
                                variables: {
                                    input: {
                                        key: showEditKey,
                                        value
                                    },
                                },
                                refetchQueries: [{ query: SettingsDocument }],
                            });
                        },
                    },
                ]}
            />
        </IonPage>
    );
};

export default SettingsPage;
