import React from "react";
import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonMenuButton,
    IonButton,
    IonGrid,
    IonCol,
    IonRow,
} from "@ionic/react";
import { useFirebase } from "../providers/firebase-provider";
import StatsBySource from "../components/stats-by-source";
import StatsTimeline from "../components/stats-timeline";
import StatsBySourceLastStories from "../components/stats-by-source-last-stories";
import StatsClicksByStory from "../components/stats-clicks-by-story";
import StatsClicksBySource from "../components/stats-clicks-by-source";

const HomePage: React.FC = () => {
    const { auth } = useFirebase();
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Inicio</IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="outline"
                            onClick={async () => {
                                await auth.signOut();
                            }}
                        >
                            Salir
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonGrid>
                    <IonRow>
                        <IonCol sizeXs="12" sizeMd="5" sizeXl="4">
                            <StatsBySource />
                            <div className="ion-padding-top" ></div>
                            <StatsBySourceLastStories />
                        </IonCol>
                        <IonCol sizeXs="12" sizeMd="7" sizeXl="8">
                            <StatsTimeline />
                            <div className="ion-padding-top" ></div>
                            <StatsClicksByStory />
                            <div className="ion-padding-top" ></div>
                            <StatsClicksBySource />
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default HomePage;
