import React from "react";
import { IonList, IonItem, IonLabel, IonIcon, IonMenuToggle } from "@ionic/react";
import { home, logoRss, earthOutline, albumsOutline, alertCircleOutline, settingsOutline, gridOutline } from "ionicons/icons";

const MainMenu: React.FC = () => {
    return (
        <IonMenuToggle autoHide={false}>
            <IonList lines="none">
                <IonItem button detail={false} routerLink="/home">
                    <IonIcon icon={home} slot="start" />
                    <IonLabel>Inicio</IonLabel>
                </IonItem>
                <IonItem button detail={false} routerLink="/settings">
                    <IonIcon icon={settingsOutline} slot="start" />
                    <IonLabel>Ajustes</IonLabel>
                </IonItem>
                <IonItem button detail={false} routerLink="/sections">
                    <IonIcon icon={gridOutline} slot="start" />
                    <IonLabel>Secciones</IonLabel>
                </IonItem>
                <IonItem button detail={false} routerLink="/headlines">
                    <IonIcon icon={alertCircleOutline} slot="start" />
                    <IonLabel>Destacadas</IonLabel>
                </IonItem>
                <IonItem button detail={false} routerLink="/coverages">
                    <IonIcon icon={albumsOutline} slot="start" />
                    <IonLabel>Coberturas</IonLabel>
                </IonItem>
                <IonItem button detail={false} routerLink="/sources">
                    <IonIcon icon={earthOutline} slot="start" />
                    <IonLabel>Fuentes</IonLabel>
                </IonItem>
                <IonItem button detail={false} routerLink="/stories">
                    <IonIcon icon={logoRss} slot="start" />
                    <IonLabel>Historias</IonLabel>
                </IonItem>
            </IonList>
        </IonMenuToggle>
    );
};

export default MainMenu;
