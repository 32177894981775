import React, { useState } from "react";
import { ICoverage, useCoverageUpdateMutation, CoveragesDocument, IStory } from "../types/graphql";
import StoryItem from "./story-item";
import { IonButton, IonIcon, IonPopover, IonList, IonItem, IonAlert } from "@ionic/react";
import { ellipsisVertical } from "ionicons/icons";
import StoriesSelector from "./stories-selector";

const refetchQueries = [
    {
        query: CoveragesDocument,
    },
];

interface ICoverageItemPros {
    coverage: ICoverage | null;
}

const CoverageItem: React.FC<ICoverageItemPros> = ({ coverage }) => {
    const [showMenu, setShowMenu] = useState<Event | undefined>(undefined);
    const [showStoryMenu, setShowStoryMenu] = useState<{ event: Event; story: IStory } | undefined>(
        undefined
    );
    const [showEditName, setShowEditName] = useState(false);
    const [showEditKeywords, setShowEdiKeywords] = useState(false);
    const [showEditOrder, setShowEditOrder] = useState(false);
    const [showStorySelector, setShowStorySelector] = useState(false);
    const [updateCoverage] = useCoverageUpdateMutation();
    return (
        <>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    opacity: coverage?.pinned ? 1 : 0.4,
                }}
            >
                <h3 style={{ margin: 0 }}>{coverage?.name}</h3>
                <IonButton
                    slot="end"
                    fill="clear"
                    onClick={(event: any) => setShowMenu(event.nativeEvent)}
                >
                    <IonIcon slot="icon-only" icon={ellipsisVertical} />
                </IonButton>
            </div>
            <div
                style={{
                    flex: 1,
                    display: "flex",
                    overflow: "auto",
                    paddingBottom: 16,
                    opacity: coverage?.pinned ? 1 : 0.4,
                }}
            >
                {coverage?.stories?.map((story) => (
                    <StoryItem
                        key={story?._id}
                        story={story}
                        hideSummary
                        hideSection
                        hideCategories
                        onClickMenu={(event: any) =>
                            setShowStoryMenu({
                                event: event.nativeEvent,
                                story: story as IStory,
                            })
                        }
                    />
                ))}
                <div
                    style={{
                        height: 360,
                        minWidth: 250,
                        width: 250,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    {
                        !coverage?.auto && <IonButton fill="clear" onClick={() => setShowStorySelector(true)}>
                            Agregar Historia
                    </IonButton>
                    }
                </div>
            </div>
            <IonPopover
                event={showMenu}
                isOpen={!!showMenu}
                onDidDismiss={(e) => setShowMenu(undefined)}
            >
                <IonList lines="none">
                    <IonItem
                        button
                        detail={false}
                        onClick={() => {
                            setShowMenu(undefined);
                            updateCoverage({
                                variables: {
                                    id: coverage?._id,
                                    input: {
                                        auto: !coverage?.auto,
                                    },
                                },
                                refetchQueries,
                            });
                        }}
                    >
                        {coverage?.auto ? "Cobertura Manual" : "Cobertura Automática"}
                    </IonItem>
                    <IonItem
                        button
                        detail={false}
                        onClick={() => {
                            setShowMenu(undefined);
                            updateCoverage({
                                variables: {
                                    id: coverage?._id,
                                    input: {
                                        pinned: !coverage?.pinned,
                                    },
                                },
                                refetchQueries,
                            });
                        }}
                    >
                        {coverage?.pinned ? "Ocultar" : "Mostrar"}
                    </IonItem>
                    {
                        coverage?.auto && <IonItem
                            button
                            detail={false}
                            onClick={() => {
                                setShowMenu(undefined);
                                setShowEdiKeywords(true);
                            }}
                        >
                            Editar Keywords
                    </IonItem>
                    }
                    <IonItem
                        button
                        detail={false}
                        onClick={() => {
                            setShowMenu(undefined);
                            setShowEditName(true);
                        }}
                    >
                        Editar Nombre
                    </IonItem>
                    <IonItem
                        button
                        detail={false}
                        onClick={() => {
                            setShowMenu(undefined);
                            setShowEditOrder(true);
                        }}
                    >
                        Editar Orden
                    </IonItem>
                    <IonItem
                        button
                        detail={false}
                        onClick={() => {
                            setShowMenu(undefined);
                            updateCoverage({
                                variables: {
                                    id: coverage?._id,
                                    input: {
                                        enabled: false,
                                    },
                                },
                                refetchQueries,
                            });
                        }}
                    >
                        Eliminar
                    </IonItem>
                </IonList>
            </IonPopover>
            <IonAlert
                isOpen={showEditName}
                onDidDismiss={() => setShowEditName(false)}
                header={"Editar Nombre"}
                inputs={[
                    {
                        name: "name",
                        type: "text",
                        value: coverage?.name,
                        placeholder: "Nombre",
                    },
                ]}
                buttons={[
                    {
                        text: "Cancelar",
                        role: "cancel",
                        cssClass: "secondary",
                    },
                    {
                        text: "Salvar",
                        handler: ({ name }) => {
                            if (name) {
                                updateCoverage({
                                    variables: {
                                        id: coverage?._id,
                                        input: {
                                            name,
                                        },
                                    },
                                    refetchQueries,
                                });
                            }
                        },
                    },
                ]}
            />
            <IonAlert
                isOpen={showEditKeywords}
                onDidDismiss={() => setShowEdiKeywords(false)}
                header={"Editar Keywords"}
                inputs={[
                    {
                        name: "keywords",
                        type: "text",
                        value: coverage?.keywords,
                        placeholder: "Keywords",
                    },
                ]}
                buttons={[
                    {
                        text: "Cancelar",
                        role: "cancel",
                        cssClass: "secondary",
                    },
                    {
                        text: "Salvar",
                        handler: ({ keywords }) => {
                            if (keywords) {
                                updateCoverage({
                                    variables: {
                                        id: coverage?._id,
                                        input: {
                                            keywords,
                                        },
                                    },
                                    refetchQueries,
                                });
                            }
                        },
                    },
                ]}
            />
            <IonAlert
                isOpen={showEditOrder}
                onDidDismiss={() => setShowEditOrder(false)}
                header={"Editar Orden"}
                inputs={[
                    {
                        name: "order",
                        type: "number",
                        value: coverage?.order,
                        placeholder: "Orden",
                    },
                ]}
                buttons={[
                    {
                        text: "Cancelar",
                        role: "cancel",
                        cssClass: "secondary",
                    },
                    {
                        text: "Salvar",
                        handler: ({ order }) => {
                            updateCoverage({
                                variables: {
                                    id: coverage?._id,
                                    input: {
                                        order: Number(order),
                                    },
                                },
                                refetchQueries,
                            });
                        },
                    },
                ]}
            />
            {showStorySelector && (
                <StoriesSelector
                    open={true}
                    onClose={() => setShowStorySelector(false)}
                    exclude={coverage?.stories?.map((e) => e?._id) || []}
                    onSelect={(story: IStory) => {
                        setShowStorySelector(false);
                        updateCoverage({
                            variables: {
                                id: coverage?._id,
                                input: {
                                    stories: [
                                        ...(coverage?.stories?.map((e) => e?._id) || []),
                                        story._id,
                                    ],
                                },
                            },
                            refetchQueries,
                        });
                    }}
                />
            )}
            <IonPopover
                event={showStoryMenu?.event}
                isOpen={!!showStoryMenu}
                onDidDismiss={(e) => setShowStoryMenu(undefined)}
            >
                <IonList lines="none">
                    <IonItem
                        button
                        detail={false}
                        onClick={() => {
                            setShowStoryMenu(undefined);
                            updateCoverage({
                                variables: {
                                    id: coverage?._id,
                                    input: {
                                        stories:
                                            coverage?.stories
                                                ?.filter((e) => e?._id !== showStoryMenu?.story._id)
                                                .map((e) => e?._id) || [],
                                    },
                                },
                                refetchQueries,
                            });
                        }}
                    >
                        Eliminar
                    </IonItem>
                </IonList>
            </IonPopover>
        </>
    );
};

export default CoverageItem;
