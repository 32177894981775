import React, { CSSProperties, useState, useMemo } from "react";
import {
    IonModal,
    IonRow,
    IonCol,
    IonButton,
    IonIcon,
    IonList,
    IonSpinner,
    IonSearchbar,
} from "@ionic/react";
import { close } from "ionicons/icons";
import { useStoriesQuery, IStory } from "../types/graphql";
import StoryItemThumbnail from "./story-item-thumbnail";

interface IStoriesSelectorProps {
    onClose: Function;
    onSelect: Function;
    open: boolean;
    exclude: string[];
    style?: CSSProperties;
    size?: number;
}

const StoriesSelector: React.FC<IStoriesSelectorProps> = ({
    onClose,
    onSelect,
    open,
    exclude,
    style = {},
    size = 64,
}) => {
    const [searchText, setSearchText] = useState("");
    const { data, loading, fetchMore } = useStoriesQuery({
        variables: {
            search: searchText,
        },
    });
    const [pagination, setPagination] = useState<{ loading: boolean; completed: boolean }>({
        loading: false,
        completed: false,
    });
    const lastStoryDate = useMemo<Date | null>(
        () => (data?.stories?.length ? data.stories[data.stories.length - 1]?.date : null),
        [data]
    );
    const areMoreStories = useMemo<boolean>(
        () => !!data?.stories?.length && data.stories.length > 19,
        [data]
    );
    const onFetchMore = () => {
        fetchMore({
            variables: {
                date_lt: lastStoryDate,
            },
            updateQuery: (prev: any, { fetchMoreResult }: any) => {
                setPagination({
                    loading: false,
                    completed:
                        !fetchMoreResult?.stories?.length || fetchMoreResult.stories.length < 20,
                });
                if (!fetchMoreResult) return prev;
                return Object.assign({}, prev, {
                    stories: [...prev.stories, ...fetchMoreResult.stories],
                });
            },
        } as any);
        setPagination({ loading: true, completed: false });
    };
    return (
        <IonModal
            isOpen={open}
            backdropDismiss={false}
            onDidDismiss={() => onClose()}
            cssClass="ion-modal-scrollable"
        >
            <IonRow className="ion-align-items-center ion-padding-start">
                <IonCol>
                    <h5>Selecciona una Historia</h5>
                </IonCol>
                <IonCol className="ion-text-end">
                    <IonButton fill="clear" onClick={onClose as any}>
                        <IonIcon slot="icon-only" icon={close} />
                    </IonButton>
                </IonCol>
                <IonCol size="12">
                    <div style={{ display: "flex", justifyContent: "center", paddingRight: 20 }}>
                        <IonSearchbar
                            debounce={1000}
                            className="input-search"
                            placeholder="Buscar historias"
                            onIonChange={(e) => setSearchText(e.detail.value!)}
                        />
                    </div>
                </IonCol>
            </IonRow>

            {loading ? (
                <div className="ion-text-center">
                    <IonSpinner style={{ height: 40 }} />
                </div>
            ) : (
                <IonList className="ion-padding">
                    {data?.stories
                        ?.filter((story) => !exclude.includes(story?._id))
                        .map((story) => (
                            <StoryItemThumbnail
                                key={story?._id}
                                story={story as IStory}
                                onClick={(event: any) => onSelect(story)}
                            />
                        ))}
                    <div
                        className="ion-padding-top"
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        {lastStoryDate && areMoreStories && !pagination.completed && (
                            <div style={{ textAlign: "center" }}>
                                {pagination.loading ? (
                                    <IonSpinner style={{ height: 40 }} />
                                ) : (
                                    <IonButton
                                        color="dark"
                                        fill="outline"
                                        onClick={() => onFetchMore()}
                                    >
                                        Mostar Más
                                    </IonButton>
                                )}
                            </div>
                        )}
                    </div>
                </IonList>
            )}
            <div />
        </IonModal>
    );
};

export default StoriesSelector;
