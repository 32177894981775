import React, { useState } from "react";
import { IHeadline, IStory, useHeadlineUpdateMutation, HeadlinesDocument } from "../types/graphql";
import StoryItem from "./story-item";
import { IonPopover, IonList, IonItem } from "@ionic/react";
import HeadlineEditor from "./headline-editor";

interface IHeadlineItemPros {
    headline: IHeadline | null;
}

const HeadlineItem: React.FC<IHeadlineItemPros> = ({ headline }) => {
    const [showMenu, setShowMenu] = useState<Event | undefined>(undefined);
    const [showEdit, setShowEdit] = useState(false);
    const [updateHeadline] = useHeadlineUpdateMutation();
    return (
        <>
            <StoryItem
                key={headline?._id}
                story={
                    {
                        ...headline?.story,
                        date: headline?.expireAt,
                    } as IStory
                }
                hideSummary
                onClickMenu={(event: any) => setShowMenu(event.nativeEvent)}
            />
            <IonPopover
                event={showMenu}
                isOpen={!!showMenu}
                onDidDismiss={(e) => setShowMenu(undefined)}
            >
                <IonList lines="none">
                    <IonItem
                        button
                        detail={false}
                        onClick={() => {
                            setShowMenu(undefined);
                            window.open(headline?.story?.url as string, "_blank");
                        }}
                    >
                        Ver
                    </IonItem>
                    <IonItem
                        button
                        detail={false}
                        onClick={() => {
                            setShowMenu(undefined);
                            setShowEdit(true);
                        }}
                    >
                        Editar
                    </IonItem>
                    <IonItem
                        button
                        detail={false}
                        onClick={() => {
                            setShowMenu(undefined);
                            updateHeadline({
                                variables: {
                                    id: headline?._id,
                                    input: {
                                        enabled: false,
                                    },
                                },
                                refetchQueries: [{ query: HeadlinesDocument }],
                            });
                        }}
                    >
                        Eliminar
                    </IonItem>
                </IonList>
            </IonPopover>
            <HeadlineEditor
                headline={headline}
                open={showEdit}
                onClose={() => setShowEdit(false)}
            />
        </>
    );
};

export default HeadlineItem;
