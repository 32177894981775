import React, { useState } from "react";
import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonMenuButton,
    IonProgressBar,
    IonPopover,
    IonList,
    IonItem,
} from "@ionic/react";
import { useSourcesQuery, ISource, useSourceCrawlMutation } from "../types/graphql";
import SourceItem from "../components/source-item";
import FabTextButton from "../components/fab-text-button";
import SourceEditor from "../components/source-editor";
import { useHistory } from "react-router";
import { useToast } from "../providers/toast-provider";

const SourcesPage: React.FC = () => {
    const { showToast } = useToast();
    const history = useHistory();
    const { data, loading } = useSourcesQuery({ variables: { ignore_enabled: true } });
    const [crawlSource] = useSourceCrawlMutation();
    const [selected, setSelected] = useState<ISource | null>(null);
    const [showMenu, setShowMenu] = useState<Event | undefined>(undefined);
    const [showEdit, setShowEdit] = useState(false);
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Fuentes</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {loading ? (
                    <IonProgressBar type="indeterminate" />
                ) : (
                    <>
                        <div
                            className="ion-padding-top"
                            style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
                        >
                            {data?.sources?.map((source) => (
                                <SourceItem
                                    key={source?._id}
                                    source={source as ISource}
                                    onClickMenu={(event: any) => {
                                        setShowMenu(event.nativeEvent);
                                        setSelected(source as ISource);
                                    }}
                                />
                            ))}
                        </div>
                        <FabTextButton
                            style={{
                                textTransform: "none",
                                position: "fixed",
                                bottom: 16,
                                right: 16,
                            }}
                            text={"Nueva Fuente"}
                            onClick={() => {
                                setSelected({} as ISource);
                                setShowEdit(true);
                            }}
                        />
                    </>
                )}
                <IonPopover
                    event={showMenu}
                    isOpen={!!showMenu}
                    onDidDismiss={(e) => setShowMenu(undefined)}
                >
                    <IonList lines="none">
                        <IonItem
                            button
                            detail={false}
                            onClick={() => {
                                setShowMenu(undefined);
                                window.open(selected?.url as string, "_blank");
                            }}
                        >
                            Ver
                        </IonItem>
                        <IonItem
                            button
                            detail={false}
                            onClick={() => {
                                setShowMenu(undefined);
                                history.replace("/stories?source=" + selected?._id);
                            }}
                        >
                            Historias
                        </IonItem>
                        <IonItem
                            button
                            detail={false}
                            onClick={() => {
                                setShowMenu(undefined);
                                showToast("Buscando historias de " + selected?.name);
                                crawlSource({
                                    variables: {
                                        id: selected?._id,
                                    },
                                });
                            }}
                        >
                            Sincronizar
                        </IonItem>
                        <IonItem
                            button
                            detail={false}
                            onClick={() => {
                                setShowMenu(undefined);
                                setShowEdit(true);
                            }}
                        >
                            Editar
                        </IonItem>
                    </IonList>
                </IonPopover>
                <SourceEditor
                    source={selected}
                    open={showEdit}
                    onClose={() => setShowEdit(false)}
                />
            </IonContent>
        </IonPage>
    );
};

export default SourcesPage;
