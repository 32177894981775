import moment from "moment";
import React, { useState } from "react";
import {
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonCol,
    IonRow,
    IonList,
    IonItem,
    IonLabel,
    IonText,
    IonAvatar,
    IonSegment,
    IonSegmentButton,
    IonButton,
    IonSpinner,
    IonIcon,
} from "@ionic/react";
import { reload } from "ionicons/icons";
import { useStatsBySourceQuery } from "../types/graphql";

const StatsBySource: React.FC = () => {
    const [limit, setLimit] = useState(5);
    const [interval, setInterval] = useState("day");
    const { data, loading, refetch } = useStatsBySourceQuery({
        variables: {
            date_gt: moment().startOf(interval as any),
        },
        notifyOnNetworkStatusChange: true,
    });
    return (
        <IonCard>
            {loading ? (
                <IonCardContent class="card-content-loading-large">
                    <IonSpinner />
                </IonCardContent>
            ) : (
                    <>
                        <IonCardHeader>
                            <IonCardTitle>
                                <IonRow className="ion-margin-bottom">
                                    <IonCol className="ion-no-padding ion-align-self-center">
                                        Historias por Fuente
                                </IonCol>
                                    <IonCol size="auto" className="ion-no-padding ion-text-end">
                                        <IonButton
                                            fill="clear"
                                            size="small"
                                            onClick={() => refetch()}
                                        >
                                            <IonIcon slot="icon-only" icon={reload} />
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                                <IonSegment
                                    value={interval}
                                    onIonChange={(e) => setInterval(e.detail.value as string)}
                                >
                                    <IonSegmentButton value="day">
                                        <IonLabel>Hoy</IonLabel>
                                    </IonSegmentButton>
                                    <IonSegmentButton value="week">
                                        <IonLabel>Semana</IonLabel>
                                    </IonSegmentButton>
                                    <IonSegmentButton value="month">
                                        <IonLabel>Mes</IonLabel>
                                    </IonSegmentButton>
                                </IonSegment>
                            </IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent>
                            <IonList>
                                <IonItem>
                                    <IonAvatar slot="start"></IonAvatar>
                                    <IonLabel className="ion-text-wrap">Total</IonLabel>
                                    <IonText slot="end">
                                        {data?.statsBySource?.reduce(
                                            (acc: any, value: any) => acc + value.stories,
                                            0
                                        ) || "-"}
                                    </IonText>
                                </IonItem>
                                {data?.statsBySource
                                    ?.map(({ source, stories }: any) => (
                                        <IonItem
                                            key={source._id}
                                            lines="none"
                                            detail={false}
                                            routerLink={"/stories?source=" + source._id}
                                        >
                                            <IonAvatar slot="start">
                                                {source?.image && (
                                                    <img
                                                        src={source?.image?.url as string}
                                                        alt={source?.title as string}
                                                    />
                                                )}
                                            </IonAvatar>
                                            <IonLabel className="ion-text-wrap">
                                                {source?.name}
                                            </IonLabel>
                                            <IonText slot="end">{stories}</IonText>
                                        </IonItem>
                                    ))
                                    .slice(0, limit)}
                                {data?.statsBySource && data?.statsBySource?.length > 5 && (
                                    <div className="ion-margin-top ion-text-center">
                                        <IonButton
                                            color="dark"
                                            fill="outline"
                                            onClick={() => (limit === 5 ? setLimit(100) : setLimit(5))}
                                        >
                                            Mostrar {limit === 5 ? "Todas" : "Menos"}
                                        </IonButton>
                                    </div>
                                )}
                            </IonList>
                        </IonCardContent>
                    </>
                )}
        </IonCard>
    );
};

export default StatsBySource;
