import moment from "moment";
import React, { useMemo } from "react";
import {
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCardContent,
    IonRow,
    IonCol,
    IonButton,
    IonIcon,
} from "@ionic/react";
import { IStory, ICategory } from "../types/graphql";
import { ellipsisVertical } from "ionicons/icons";
import SectionBadge from "./section-badge";
import CategoriesBadges from "./categories-badges";

interface IStoryItemPros {
    story: IStory | null;
    hideSummary?: boolean;
    hideSection?: boolean;
    hideCategories?: boolean;
    onClickMenu?: any;
}

const StoryItem: React.FC<IStoryItemPros> = ({ story, hideSummary, hideSection, hideCategories = true, onClickMenu }) => {
    const summary = useMemo<string>(() => {
        if (hideSummary) {
            return "";
        }
        const LENGTH = 150;
        return story?.summary && story.summary.length > LENGTH
            ? story.summary.substr(0, LENGTH) + "..."
            : story?.summary || "";
    }, [hideSummary, story]);
    return (
        <div style={{ minWidth: 320, width: 320 }}>
            <IonCard>
                {story?.image && (
                    <img src={story?.image?.url as string} alt={story?.title as string} />
                )}
                <IonCardHeader>
                    <IonCardSubtitle>
                        {story?.source?.name}
                    </IonCardSubtitle>
                    <IonCardTitle>
                        <a
                            href={story?.url}
                            style={{ textDecoration: "none" }}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {story?.title}
                        </a>
                    </IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                    {!story?.enabled && (
                        <h4 style={{ fontWeight: "bold", color: "red", marginBottom: 16 }}>
                            OCULTA
                        </h4>
                    )}
                    {summary}
                    {
                        !hideSection && <div className="ion-padding-top">
                            <SectionBadge section={story?.section as string} />
                        </div>
                    }
                    {
                        !hideCategories && <CategoriesBadges categories={story?.categories as ICategory[]} />
                    }
                    <IonRow style={{ paddingTop: 16 }}>
                        <IonCol size="auto" className="ion-align-self-center">
                            <p style={{ fontWeight: "bold" }}>
                                {moment(story?.date).format("LLL")}
                            </p>
                        </IonCol>
                        <IonCol className="ion-no-padding ion-text-end">
                            <IonButton fill="clear" size="small" onClick={onClickMenu}>
                                <IonIcon slot="icon-only" icon={ellipsisVertical} />
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonCardContent>
            </IonCard>
        </div>
    );
};

export default StoryItem;
