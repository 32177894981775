import moment from "moment";
import React, { CSSProperties } from "react";
import {
    IonModal,
    IonRow,
    IonCol,
    IonButton,
    IonIcon,
    IonList,
    IonProgressBar,
    IonItem,
    IonThumbnail,
    IonLabel,
    IonText,
} from "@ionic/react";
import { close } from "ionicons/icons";

interface ISourcePreviewProps {
    onClose: Function;
    open: boolean;
    loading: boolean;
    preview: any;
    style?: CSSProperties;
}

const SourcePreview: React.FC<ISourcePreviewProps> = ({
    loading,
    preview,
    onClose,
    open,
    style = {},
}) => {
    return (
        <IonModal
            isOpen={open}
            backdropDismiss={false}
            onDidDismiss={() => onClose()}
            cssClass="ion-modal-scrollable"
        >
            <IonRow className="ion-align-items-center ion-padding-start">
                <IonCol>
                    <h5>Preview</h5>
                </IonCol>
                <IonCol className="ion-text-end">
                    <IonButton fill="clear" onClick={onClose as any}>
                        <IonIcon slot="icon-only" icon={close} />
                    </IonButton>
                </IonCol>
                {loading && <IonProgressBar className="ion-margin-end" type="indeterminate" />}
            </IonRow>
            <IonList lines="none" style={{ padding: 32 }}>
                {preview?.sourcePreview?.map((story: any) => (
                    <IonItem key={story.key}>
                        <IonThumbnail slot="start">
                            {story?.image && (
                                <img src={story?.image as string} alt={story?.title as string} />
                            )}
                        </IonThumbnail>
                        <IonLabel className="ion-text-wrap">
                            <IonText>
                                <a href={story?.url} target="_blank" rel="noopener noreferrer">
                                    {story?.title}
                                </a>
                            </IonText>
                            <p>{story?.summary?.substring(0, 200)}</p>
                            <IonText>
                                <p>{moment(story?.date).format("LLL")}</p>
                            </IonText>
                        </IonLabel>
                    </IonItem>
                ))}
            </IonList>
            <div className="ion-padding ion-text-end"></div>
        </IonModal>
    );
};

export default SourcePreview;
