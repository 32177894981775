import React from "react";
import { Redirect, Route } from "react-router-dom";
import {
    IonRouterOutlet,
    IonSplitPane,
    IonMenu,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonProgressBar,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import useFirebaseAuth from "./hooks/use-firebase-auth";
import MainMenu from "./components/main-menu";
import LoginPage from "./pages/login-page";
import HomePage from "./pages/home-page";
import StoriesPage from "./pages/stories-page";
import SourcesPage from "./pages/sources.page";
import CoveragesPage from "./pages/coverages-page";
import HeadlinesPage from "./pages/headlines-page";
import SettingsPage from "./pages/settings-page";
import SectionsPage from "./pages/sections-page";

export const Routes: React.FC = () => {
    const { user, loading } = useFirebaseAuth();

    return loading ? (
        <IonProgressBar type="indeterminate" />
    ) : (
            <IonReactRouter>
                {user ? (
                    <IonSplitPane contentId="main">
                        <IonMenu contentId="main">
                            <IonHeader className="no-borders">
                                <IonToolbar>
                                    <IonTitle>Menu</IonTitle>
                                </IonToolbar>
                            </IonHeader>
                            <IonContent className="menu-content">
                                <MainMenu />
                            </IonContent>
                        </IonMenu>
                        <IonRouterOutlet id="main">
                            <Route exact path="/sections" component={SectionsPage} />
                            <Route exact path="/settings" component={SettingsPage} />
                            <Route exact path="/headlines" component={HeadlinesPage} />
                            <Route exact path="/coverages" component={CoveragesPage} />
                            <Route exact path="/sources" component={SourcesPage} />
                            <Route exact path="/stories" component={StoriesPage} />
                            <Route exact path="/home" component={HomePage} />
                            <Route render={() => <Redirect to="/home" />} />
                        </IonRouterOutlet>
                    </IonSplitPane>
                ) : (
                        <IonRouterOutlet>
                            <Route exact path="/login" component={LoginPage} />
                            <Route render={() => <Redirect to="/login" />} />
                        </IonRouterOutlet>
                    )}
            </IonReactRouter>
        );
};
