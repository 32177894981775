import React, { createContext, ReactNode, useContext, useState } from "react";
import { IonToast } from "@ionic/react";

interface IToast {
    showToast: (message: string) => void;
}

const ToastContext = createContext<IToast>({} as IToast);

export const useToast = () => useContext(ToastContext);

export const ToastProvider = ({ children }: { children: ReactNode }) => {
    const [text, setText] = useState<string | undefined>();

    const value = {
        showToast: (message: string) => setText(message),
    };

    return (
        <ToastContext.Provider value={value}>
            <>
                {children}
                <IonToast
                    cssClass="ion-text-center"
                    isOpen={!!text}
                    onDidDismiss={() => setText(undefined)}
                    message={text}
                    duration={500}
                />
            </>
        </ToastContext.Provider>
    );
};
