import * as firebase from "firebase/app";
import React, { useMemo, ReactNode } from "react";
import { BatchHttpLink } from "apollo-link-batch-http";
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const httpLink = new BatchHttpLink({
    uri: process.env.REACT_APP_GRAPHQL,
});

const authLink = setContext(async (_, { headers }) => {
    const currentUser = await firebase.auth().currentUser;
    return {
        headers: {
            ...headers,
            authorization: `Bearer ${await currentUser?.getIdToken()}`,
        },
    };
});

const getClient = () => {
    return new ApolloClient({
        link: authLink.concat(httpLink as any),
        cache: new InMemoryCache(),
    });
};

export const GraphQLProvider = ({ children }: { children: ReactNode }) => {
    const client = useMemo(getClient, []);
    return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
