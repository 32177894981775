import React from "react";
import { IonContent, IonPage, IonImg, IonList, IonItem, IonIcon } from "@ionic/react";
import * as firebase from "firebase/app";
import { logoGoogle } from "ionicons/icons";
import { useFirebase } from "../providers/firebase-provider";

const LoginPage: React.FC = () => {
    const { auth } = useFirebase();
    const onSignIn = async (provider: string) => {
        try {
            switch (provider) {
                case "google":
                    const googleProvider = new firebase.auth.GoogleAuthProvider();
                    await auth.signInWithRedirect(googleProvider);
                    break;
                case "github":
                    const githubProvider = new firebase.auth.GithubAuthProvider();
                    await auth.signInWithRedirect(githubProvider);
                    break;
                case "facebook":
                    const facebookProvider = new firebase.auth.FacebookAuthProvider();
                    await auth.signInWithRedirect(facebookProvider);
                    break;
                case "apple":
                    const appleProvider = new firebase.auth.OAuthProvider("apple.com");
                    await auth.signInWithRedirect(appleProvider);
                    break;
                case "microsoft":
                    const microsoftProvider = new firebase.auth.OAuthProvider("microsoft.com");
                    microsoftProvider.setCustomParameters({
                        tenant: process.env.REACT_APP_MICROSOFT_TENTANT,
                    });
                    microsoftProvider.addScope("mail.read");
                    await auth.signInWithRedirect(microsoftProvider);
                    break;
            }
            // Reload screen
            // to refresh connected user
            // window.location.reload();
        } catch {}
    };

    return (
        <IonPage>
            <IonContent>
                <div
                    style={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <div>
                        <IonImg style={{ width: 64, margin: "auto" }} src="assets/icon/icon.png" />
                        <h1 style={{ fontSize: 44 }} className="ion-text-center">
                            Cubantropia
                        </h1>
                        <h2 className="ion-text-center">Interfaz de administración</h2>
                        <IonList
                            lines="none"
                            style={{
                                width: 250,
                                margin: "20px auto",
                                backgroundColor: "transparent",
                            }}
                        >
                            <IonItem detail={false} button onClick={() => onSignIn("google")}>
                                <IonIcon icon={logoGoogle} style={{ marginRight: 20 }} />
                                <span style={{ fontSize: 18 }}>Iniciar con Google</span>
                            </IonItem>
                        </IonList>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default LoginPage;
