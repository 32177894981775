import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `ID` scalar type represents a unique MongoDB identifier in collection. MongoDB by default use 12-byte ObjectId value (https://docs.mongodb.com/manual/reference/bson-types/#objectid). But MongoDB also may accepts string or integer as correct values for _id field. */
  MongoID: any;
  Date: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
}

export interface IQuery {
  readonly __typename?: 'Query';
  readonly image?: Maybe<IImage>;
  readonly source?: Maybe<ISource>;
  readonly sources?: Maybe<ReadonlyArray<Maybe<ISource>>>;
  readonly category?: Maybe<ICategory>;
  readonly categories?: Maybe<ReadonlyArray<Maybe<ICategory>>>;
  readonly story?: Maybe<IStory>;
  readonly stories?: Maybe<ReadonlyArray<Maybe<IStory>>>;
  readonly headlines?: Maybe<ReadonlyArray<Maybe<IHeadline>>>;
  readonly setting?: Maybe<ISetting>;
  readonly settings?: Maybe<ReadonlyArray<Maybe<ISetting>>>;
  readonly coverages?: Maybe<ReadonlyArray<Maybe<ICoverage>>>;
  readonly feed?: Maybe<IFeed>;
  readonly feedOfSources?: Maybe<ReadonlyArray<Maybe<IFeedOfSource>>>;
  readonly interaction?: Maybe<IInteraction>;
  readonly interactions?: Maybe<ReadonlyArray<Maybe<IInteraction>>>;
  readonly sections?: Maybe<ReadonlyArray<Maybe<ISection>>>;
  readonly statsBySource?: Maybe<ReadonlyArray<Maybe<IStatsSource>>>;
  readonly statsBySourceLastStories?: Maybe<ReadonlyArray<Maybe<IStatsSource>>>;
  readonly statsClicksByStory?: Maybe<ReadonlyArray<Maybe<IStatsClicksStory>>>;
  readonly statsClicksBySource?: Maybe<ReadonlyArray<Maybe<IStatsClicksSource>>>;
  readonly statsTimeline?: Maybe<ReadonlyArray<Maybe<IStatsTimelineItem>>>;
}


export interface IQueryImageArgs {
  id: Scalars['MongoID'];
}


export interface IQuerySourceArgs {
  id: Scalars['MongoID'];
}


export interface IQuerySourcesArgs {
  ignore_enabled?: Maybe<Scalars['Boolean']>;
}


export interface IQueryCategoryArgs {
  id: Scalars['MongoID'];
}


export interface IQueryCategoriesArgs {
  ids?: Maybe<ReadonlyArray<Maybe<Scalars['MongoID']>>>;
}


export interface IQueryStoryArgs {
  id: Scalars['MongoID'];
}


export interface IQueryStoriesArgs {
  ids?: Maybe<ReadonlyArray<Maybe<Scalars['MongoID']>>>;
  limit?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  date_gt?: Maybe<Scalars['Date']>;
  date_lt?: Maybe<Scalars['Date']>;
  sources_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  sources_nin?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  categories_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  categories_nin?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  ignore_enabled?: Maybe<Scalars['Boolean']>;
  stories_like?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  stories_dislike?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  section?: Maybe<Scalars['String']>;
  related?: Maybe<Scalars['MongoID']>;
  related_text?: Maybe<Scalars['String']>;
}


export interface IQueryHeadlinesArgs {
  sources_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  sources_nin?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  ignore_expireAt?: Maybe<Scalars['Boolean']>;
  ignore_enabled?: Maybe<Scalars['Boolean']>;
}


export interface IQuerySettingArgs {
  key: Scalars['String'];
  language?: Maybe<Scalars['String']>;
}


export interface IQueryCoveragesArgs {
  language?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['Date']>;
  updatedAt_lt?: Maybe<Scalars['Date']>;
  sources_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  sources_nin?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  pinned?: Maybe<Scalars['Boolean']>;
  ignore_enabled?: Maybe<Scalars['Boolean']>;
}


export interface IQueryFeedArgs {
  ids?: Maybe<ReadonlyArray<Maybe<Scalars['MongoID']>>>;
  search?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  date_gt?: Maybe<Scalars['Date']>;
  date_lt?: Maybe<Scalars['Date']>;
  sources_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  sources_nin?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  categories_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  categories_nin?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  stories_like?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  stories_dislike?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
}


export interface IQueryInteractionArgs {
  id: Scalars['MongoID'];
}


export interface IQueryInteractionsArgs {
  ids?: Maybe<ReadonlyArray<Maybe<Scalars['MongoID']>>>;
}


export interface IQuerySectionsArgs {
  ids?: Maybe<ReadonlyArray<Maybe<Scalars['MongoID']>>>;
}


export interface IQueryStatsBySourceArgs {
  date_gt?: Maybe<Scalars['Date']>;
  date_lt?: Maybe<Scalars['Date']>;
}


export interface IQueryStatsClicksByStoryArgs {
  limit?: Maybe<Scalars['Int']>;
  realtime?: Maybe<Scalars['Boolean']>;
  date_gt?: Maybe<Scalars['Date']>;
  date_lt?: Maybe<Scalars['Date']>;
}


export interface IQueryStatsClicksBySourceArgs {
  limit?: Maybe<Scalars['Int']>;
  realtime?: Maybe<Scalars['Boolean']>;
  date_gt?: Maybe<Scalars['Date']>;
  date_lt?: Maybe<Scalars['Date']>;
}


export interface IQueryStatsTimelineArgs {
  date_gt?: Maybe<Scalars['Date']>;
  date_lt?: Maybe<Scalars['Date']>;
  sources_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  sources_nin?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  group_by_hours?: Maybe<Scalars['Boolean']>;
}

export interface IImage {
  readonly __typename?: 'Image';
  readonly path: Scalars['String'];
  readonly url: Scalars['String'];
  readonly mime?: Maybe<Scalars['String']>;
  readonly ext?: Maybe<Scalars['String']>;
  readonly originalUrl?: Maybe<Scalars['String']>;
  readonly used?: Maybe<Scalars['Boolean']>;
  readonly _id: Scalars['MongoID'];
  readonly updatedAt?: Maybe<Scalars['Date']>;
  readonly createdAt?: Maybe<Scalars['Date']>;
}



export interface ISource {
  readonly __typename?: 'Source';
  readonly key?: Maybe<Scalars['String']>;
  readonly image?: Maybe<IImage>;
  readonly imageStory?: Maybe<IImage>;
  readonly name: Scalars['String'];
  readonly description?: Maybe<Scalars['String']>;
  readonly interval?: Maybe<EnumSourceInterval>;
  readonly url: Scalars['String'];
  readonly feed: Scalars['String'];
  readonly scraper: Scalars['String'];
  readonly scraperEnabled?: Maybe<Scalars['Boolean']>;
  readonly scraperRegex?: Maybe<Scalars['String']>;
  readonly scraperSlice?: Maybe<Scalars['Float']>;
  readonly scraperSkipMeta?: Maybe<Scalars['Boolean']>;
  readonly scraperUseBrowser?: Maybe<Scalars['Boolean']>;
  readonly enabled?: Maybe<Scalars['Boolean']>;
  readonly section?: Maybe<EnumSourceSection>;
  readonly contact?: Maybe<Scalars['String']>;
  readonly government?: Maybe<Scalars['Boolean']>;
  readonly _id: Scalars['MongoID'];
  readonly updatedAt?: Maybe<Scalars['Date']>;
  readonly createdAt?: Maybe<Scalars['Date']>;
}

export enum EnumSourceInterval {
  HOURLY = 'hourly',
  MORNING = 'morning',
  AFTERNOON = 'afternoon',
  SEMIDAILY = 'semiDaily',
  DAILY = 'daily'
}

export enum EnumSourceSection {
  NEWS = 'news',
  SCIENCE = 'science',
  SPORTS = 'sports',
  CULTURE = 'culture',
  ECONOMY = 'economy',
  HEALTH = 'health'
}

export interface ICategory {
  readonly __typename?: 'Category';
  readonly name: Scalars['String'];
  readonly type?: Maybe<EnumCategoryType>;
  readonly similar?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly image?: Maybe<IImage>;
  readonly section?: Maybe<EnumCategorySection>;
  readonly _id: Scalars['MongoID'];
  readonly updatedAt?: Maybe<Scalars['Date']>;
  readonly createdAt?: Maybe<Scalars['Date']>;
}

export enum EnumCategoryType {
  GENERIC = 'generic',
  PERSON = 'person',
  PLACE = 'place',
  EVENT = 'event'
}

export enum EnumCategorySection {
  NEWS = 'news',
  SCIENCE = 'science',
  SPORTS = 'sports',
  CULTURE = 'culture',
  ECONOMY = 'economy',
  HEALTH = 'health'
}

export interface IStory {
  readonly __typename?: 'Story';
  readonly key: Scalars['String'];
  readonly image?: Maybe<IImage>;
  readonly title: Scalars['String'];
  readonly summary?: Maybe<Scalars['String']>;
  readonly url: Scalars['String'];
  readonly source?: Maybe<ISource>;
  readonly date?: Maybe<Scalars['Date']>;
  readonly categories?: Maybe<ReadonlyArray<Maybe<ICategory>>>;
  readonly type?: Maybe<EnumStoryType>;
  readonly enabled?: Maybe<Scalars['Boolean']>;
  readonly locked?: Maybe<Scalars['Boolean']>;
  readonly stats?: Maybe<IStoryStats>;
  readonly section?: Maybe<EnumStorySection>;
  readonly _id: Scalars['MongoID'];
  readonly updatedAt?: Maybe<Scalars['Date']>;
  readonly createdAt?: Maybe<Scalars['Date']>;
}

export enum EnumStoryType {
  NEWS = 'news'
}

export interface IStoryStats {
  readonly __typename?: 'StoryStats';
  readonly views?: Maybe<Scalars['Float']>;
  readonly clicks?: Maybe<Scalars['Float']>;
  readonly likes?: Maybe<Scalars['Float']>;
  readonly dislikes?: Maybe<Scalars['Float']>;
}

export enum EnumStorySection {
  NEWS = 'news',
  SCIENCE = 'science',
  SPORTS = 'sports',
  CULTURE = 'culture',
  ECONOMY = 'economy',
  HEALTH = 'health'
}

export interface IHeadline {
  readonly __typename?: 'Headline';
  readonly story?: Maybe<IStory>;
  readonly order?: Maybe<Scalars['Float']>;
  readonly expireAt?: Maybe<Scalars['Date']>;
  readonly enabled?: Maybe<Scalars['Boolean']>;
  readonly _id: Scalars['MongoID'];
  readonly updatedAt?: Maybe<Scalars['Date']>;
  readonly createdAt?: Maybe<Scalars['Date']>;
}

export interface ISetting {
  readonly __typename?: 'Setting';
  readonly key?: Maybe<Scalars['String']>;
  readonly value?: Maybe<Scalars['JSON']>;
  readonly _id: Scalars['MongoID'];
  readonly updatedAt?: Maybe<Scalars['Date']>;
  readonly createdAt?: Maybe<Scalars['Date']>;
}


export interface ICoverage {
  readonly __typename?: 'Coverage';
  readonly name?: Maybe<Scalars['String']>;
  readonly auto?: Maybe<Scalars['Boolean']>;
  readonly keywords?: Maybe<Scalars['String']>;
  readonly stories?: Maybe<ReadonlyArray<Maybe<IStory>>>;
  readonly order?: Maybe<Scalars['Float']>;
  readonly pinned?: Maybe<Scalars['Boolean']>;
  readonly enabled?: Maybe<Scalars['Boolean']>;
  readonly translations?: Maybe<Scalars['JSON']>;
  readonly _id: Scalars['MongoID'];
  readonly updatedAt?: Maybe<Scalars['Date']>;
  readonly createdAt?: Maybe<Scalars['Date']>;
}

export interface IFeed {
  readonly __typename?: 'Feed';
  readonly banner?: Maybe<IBanner>;
  readonly headlines?: Maybe<ReadonlyArray<Maybe<IStory>>>;
  readonly stories?: Maybe<ReadonlyArray<Maybe<IStory>>>;
  readonly coverages?: Maybe<ReadonlyArray<Maybe<ICoverage>>>;
}

export interface IBanner {
  readonly __typename?: 'Banner';
  readonly title?: Maybe<Scalars['String']>;
  readonly url?: Maybe<Scalars['String']>;
}

export interface IFeedOfSource {
  readonly __typename?: 'FeedOfSource';
  readonly source?: Maybe<ISource>;
  readonly stories?: Maybe<ReadonlyArray<Maybe<IStory>>>;
}

export interface IInteraction {
  readonly __typename?: 'Interaction';
  readonly story?: Maybe<Scalars['MongoID']>;
  readonly user: Scalars['String'];
  readonly interaction?: Maybe<EnumInteractionInteraction>;
  readonly _id: Scalars['MongoID'];
  readonly updatedAt?: Maybe<Scalars['Date']>;
  readonly createdAt?: Maybe<Scalars['Date']>;
}

export enum EnumInteractionInteraction {
  LIKE = 'like',
  DISLIKE = 'dislike'
}

export interface ISection {
  readonly __typename?: 'Section';
  readonly key?: Maybe<EnumSectionKey>;
  readonly terms?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly _id: Scalars['MongoID'];
  readonly updatedAt?: Maybe<Scalars['Date']>;
  readonly createdAt?: Maybe<Scalars['Date']>;
}

export enum EnumSectionKey {
  NEWS = 'news',
  SCIENCE = 'science',
  SPORTS = 'sports',
  CULTURE = 'culture',
  ECONOMY = 'economy',
  HEALTH = 'health'
}

export interface IStatsSource {
  readonly __typename?: 'StatsSource';
  readonly source?: Maybe<ISource>;
  readonly stories?: Maybe<Scalars['Int']>;
  readonly lastStory?: Maybe<IStory>;
}

export interface IStatsClicksStory {
  readonly __typename?: 'StatsClicksStory';
  readonly story?: Maybe<IStory>;
  readonly clicks?: Maybe<Scalars['Int']>;
}

export interface IStatsClicksSource {
  readonly __typename?: 'StatsClicksSource';
  readonly source?: Maybe<ISource>;
  readonly clicks?: Maybe<Scalars['Int']>;
}

export interface IStatsTimelineItem {
  readonly __typename?: 'StatsTimelineItem';
  readonly date?: Maybe<Scalars['Date']>;
  readonly count?: Maybe<Scalars['Int']>;
}

export interface IMutation {
  readonly __typename?: 'Mutation';
  readonly imageDownload?: Maybe<IImage>;
  readonly sourcePreview?: Maybe<ReadonlyArray<Maybe<IScraperStory>>>;
  readonly sourceCreate?: Maybe<ISource>;
  readonly sourceUpdate?: Maybe<ISource>;
  readonly sourceCrawl?: Maybe<ISource>;
  readonly storiesClassifySection?: Maybe<Scalars['String']>;
  readonly storyUpdate?: Maybe<IStory>;
  readonly headlineCreate?: Maybe<IHeadline>;
  readonly headlineUpdate?: Maybe<IHeadline>;
  readonly settingUpdate?: Maybe<ISetting>;
  readonly coverageCreate?: Maybe<ICoverage>;
  readonly coverageUpdate?: Maybe<ICoverage>;
  readonly interactionCreate?: Maybe<IInteraction>;
  readonly sectionPreview?: Maybe<Scalars['JSON']>;
  readonly sectionCreate?: Maybe<ISection>;
  readonly sectionUpdate?: Maybe<ISection>;
}


export interface IMutationImageDownloadArgs {
  url: Scalars['String'];
}


export interface IMutationSourcePreviewArgs {
  source: ISourceInput;
}


export interface IMutationSourceCreateArgs {
  source: ISourceInput;
}


export interface IMutationSourceUpdateArgs {
  id: Scalars['MongoID'];
  source: ISourceInput;
}


export interface IMutationSourceCrawlArgs {
  id: Scalars['MongoID'];
}


export interface IMutationStoriesClassifySectionArgs {
  date_gt?: Maybe<Scalars['Date']>;
  date_lt?: Maybe<Scalars['Date']>;
}


export interface IMutationStoryUpdateArgs {
  id: Scalars['MongoID'];
  story: IStoryInput;
}


export interface IMutationHeadlineCreateArgs {
  headline: IHeadlineInput;
  notify?: Maybe<Scalars['Boolean']>;
}


export interface IMutationHeadlineUpdateArgs {
  id: Scalars['MongoID'];
  headline: IHeadlineInput;
}


export interface IMutationSettingUpdateArgs {
  setting: ISettingInput;
}


export interface IMutationCoverageCreateArgs {
  coverage: ICoverageInput;
}


export interface IMutationCoverageUpdateArgs {
  id: Scalars['MongoID'];
  coverage: ICoverageInput;
}


export interface IMutationInteractionCreateArgs {
  interaction: IInteractionInput;
}


export interface IMutationSectionPreviewArgs {
  text: Scalars['String'];
}


export interface IMutationSectionCreateArgs {
  section: ISectionInput;
}


export interface IMutationSectionUpdateArgs {
  id: Scalars['MongoID'];
  section: ISectionInput;
}

export interface IScraperStory {
  readonly __typename?: 'ScraperStory';
  readonly key?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly summary?: Maybe<Scalars['String']>;
  readonly date?: Maybe<Scalars['Date']>;
  readonly categories?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly url?: Maybe<Scalars['String']>;
  readonly image?: Maybe<Scalars['String']>;
}

export interface ISourceInput {
  readonly key?: Maybe<Scalars['String']>;
  readonly image?: Maybe<Scalars['MongoID']>;
  readonly imageStory?: Maybe<Scalars['MongoID']>;
  readonly name: Scalars['String'];
  readonly description?: Maybe<Scalars['String']>;
  readonly interval?: Maybe<EnumSourceInterval>;
  readonly url: Scalars['String'];
  readonly feed: Scalars['String'];
  readonly scraper: Scalars['String'];
  readonly scraperEnabled?: Maybe<Scalars['Boolean']>;
  readonly scraperRegex?: Maybe<Scalars['String']>;
  readonly scraperSlice?: Maybe<Scalars['Float']>;
  readonly scraperSkipMeta?: Maybe<Scalars['Boolean']>;
  readonly scraperUseBrowser?: Maybe<Scalars['Boolean']>;
  readonly enabled?: Maybe<Scalars['Boolean']>;
  readonly section?: Maybe<EnumSourceSection>;
  readonly contact?: Maybe<Scalars['String']>;
  readonly government?: Maybe<Scalars['Boolean']>;
}

export interface IStoryInput {
  readonly key: Scalars['String'];
  readonly image?: Maybe<Scalars['MongoID']>;
  readonly title: Scalars['String'];
  readonly summary?: Maybe<Scalars['String']>;
  readonly url: Scalars['String'];
  readonly source?: Maybe<Scalars['MongoID']>;
  readonly date?: Maybe<Scalars['Date']>;
  readonly categories?: Maybe<ReadonlyArray<Maybe<Scalars['MongoID']>>>;
  readonly type?: Maybe<EnumStoryType>;
  readonly enabled?: Maybe<Scalars['Boolean']>;
  readonly locked?: Maybe<Scalars['Boolean']>;
  readonly stats?: Maybe<IStoryStatsInput>;
  readonly section?: Maybe<EnumStorySection>;
  readonly _id?: Maybe<Scalars['MongoID']>;
  readonly updatedAt?: Maybe<Scalars['Date']>;
  readonly createdAt?: Maybe<Scalars['Date']>;
}

export interface IStoryStatsInput {
  readonly views?: Maybe<Scalars['Float']>;
  readonly clicks?: Maybe<Scalars['Float']>;
  readonly likes?: Maybe<Scalars['Float']>;
  readonly dislikes?: Maybe<Scalars['Float']>;
}

export interface IHeadlineInput {
  readonly story?: Maybe<Scalars['MongoID']>;
  readonly order?: Maybe<Scalars['Float']>;
  readonly expireAt?: Maybe<Scalars['Date']>;
  readonly enabled?: Maybe<Scalars['Boolean']>;
}

export interface ISettingInput {
  readonly key?: Maybe<Scalars['String']>;
  readonly value?: Maybe<Scalars['JSON']>;
}

export interface ICoverageInput {
  readonly name?: Maybe<Scalars['String']>;
  readonly auto?: Maybe<Scalars['Boolean']>;
  readonly keywords?: Maybe<Scalars['String']>;
  readonly stories?: Maybe<ReadonlyArray<Maybe<Scalars['MongoID']>>>;
  readonly order?: Maybe<Scalars['Float']>;
  readonly pinned?: Maybe<Scalars['Boolean']>;
  readonly enabled?: Maybe<Scalars['Boolean']>;
  readonly translations?: Maybe<Scalars['JSON']>;
}

export interface IInteractionInput {
  readonly story?: Maybe<Scalars['MongoID']>;
  readonly user: Scalars['String'];
  readonly interaction?: Maybe<EnumInteractionInteraction>;
  readonly _id?: Maybe<Scalars['MongoID']>;
  readonly updatedAt?: Maybe<Scalars['Date']>;
  readonly createdAt?: Maybe<Scalars['Date']>;
}

export interface ISectionInput {
  readonly key?: Maybe<EnumSectionKey>;
  readonly terms?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly _id?: Maybe<Scalars['MongoID']>;
  readonly updatedAt?: Maybe<Scalars['Date']>;
  readonly createdAt?: Maybe<Scalars['Date']>;
}

export type ICoveragesQueryVariables = Exact<{
  sources_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  sources_nin?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  updatedAt_lt?: Maybe<Scalars['Date']>;
  updatedAt_gt?: Maybe<Scalars['Date']>;
  pinned?: Maybe<Scalars['Boolean']>;
  ignore_enabled?: Maybe<Scalars['Boolean']>;
}>;


export type ICoveragesQuery = { readonly __typename?: 'Query', readonly coverages?: Maybe<ReadonlyArray<Maybe<(
    { readonly __typename?: 'Coverage' }
    & ICoverageFragment
  )>>> };

export type ICoverageCreateMutationVariables = Exact<{
  input: ICoverageInput;
}>;


export type ICoverageCreateMutation = { readonly __typename?: 'Mutation', readonly coverageCreate?: Maybe<{ readonly __typename?: 'Coverage', readonly _id: any }> };

export type ICoverageUpdateMutationVariables = Exact<{
  id: Scalars['MongoID'];
  input: ICoverageInput;
}>;


export type ICoverageUpdateMutation = { readonly __typename?: 'Mutation', readonly coverageUpdate?: Maybe<{ readonly __typename?: 'Coverage', readonly _id: any }> };

export type ICoverageFragment = { readonly __typename?: 'Coverage', readonly _id: any, readonly name?: Maybe<string>, readonly auto?: Maybe<boolean>, readonly keywords?: Maybe<string>, readonly order?: Maybe<number>, readonly enabled?: Maybe<boolean>, readonly pinned?: Maybe<boolean>, readonly updatedAt?: Maybe<any>, readonly createdAt?: Maybe<any>, readonly stories?: Maybe<ReadonlyArray<Maybe<(
    { readonly __typename?: 'Story' }
    & IStoryFragment
  )>>> };

export type IHeadlineFragment = { readonly __typename?: 'Headline', readonly _id: any, readonly order?: Maybe<number>, readonly expireAt?: Maybe<any>, readonly story?: Maybe<(
    { readonly __typename?: 'Story' }
    & IStoryFragment
  )> };

export type IImageFragment = { readonly __typename?: 'Image', readonly _id: any, readonly path: string, readonly url: string, readonly mime?: Maybe<string>, readonly ext?: Maybe<string>, readonly originalUrl?: Maybe<string> };

export type ISectionFragment = { readonly __typename?: 'Section', readonly _id: any, readonly key?: Maybe<EnumSectionKey>, readonly terms?: Maybe<ReadonlyArray<Maybe<string>>> };

export type ISourceFragment = { readonly __typename?: 'Source', readonly _id: any, readonly key?: Maybe<string>, readonly name: string, readonly description?: Maybe<string>, readonly section?: Maybe<EnumSourceSection>, readonly interval?: Maybe<EnumSourceInterval>, readonly contact?: Maybe<string>, readonly url: string, readonly feed: string, readonly scraper: string, readonly scraperEnabled?: Maybe<boolean>, readonly scraperRegex?: Maybe<string>, readonly scraperSlice?: Maybe<number>, readonly scraperSkipMeta?: Maybe<boolean>, readonly scraperUseBrowser?: Maybe<boolean>, readonly enabled?: Maybe<boolean>, readonly government?: Maybe<boolean>, readonly image?: Maybe<{ readonly __typename?: 'Image', readonly _id: any, readonly url: string }>, readonly imageStory?: Maybe<{ readonly __typename?: 'Image', readonly _id: any, readonly url: string }> };

export type IStoryFragment = { readonly __typename?: 'Story', readonly _id: any, readonly key: string, readonly url: string, readonly title: string, readonly summary?: Maybe<string>, readonly type?: Maybe<EnumStoryType>, readonly section?: Maybe<EnumStorySection>, readonly date?: Maybe<any>, readonly enabled?: Maybe<boolean>, readonly categories?: Maybe<ReadonlyArray<Maybe<{ readonly __typename?: 'Category', readonly _id: any, readonly name: string }>>>, readonly source?: Maybe<{ readonly __typename?: 'Source', readonly _id: any, readonly name: string }>, readonly image?: Maybe<{ readonly __typename?: 'Image', readonly _id: any, readonly url: string }> };

export type IHeadlinesQueryVariables = Exact<{
  sources_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  sources_nin?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  ignore_enabled?: Maybe<Scalars['Boolean']>;
  ignore_expireAt?: Maybe<Scalars['Boolean']>;
}>;


export type IHeadlinesQuery = { readonly __typename?: 'Query', readonly headlines?: Maybe<ReadonlyArray<Maybe<(
    { readonly __typename?: 'Headline' }
    & IHeadlineFragment
  )>>> };

export type IHeadlineCreateMutationVariables = Exact<{
  notify?: Maybe<Scalars['Boolean']>;
  input: IHeadlineInput;
}>;


export type IHeadlineCreateMutation = { readonly __typename?: 'Mutation', readonly headlineCreate?: Maybe<{ readonly __typename?: 'Headline', readonly _id: any }> };

export type IHeadlineUpdateMutationVariables = Exact<{
  id: Scalars['MongoID'];
  input: IHeadlineInput;
}>;


export type IHeadlineUpdateMutation = { readonly __typename?: 'Mutation', readonly headlineUpdate?: Maybe<{ readonly __typename?: 'Headline', readonly _id: any }> };

export type IImageDownloadMutationVariables = Exact<{
  url: Scalars['String'];
}>;


export type IImageDownloadMutation = { readonly __typename?: 'Mutation', readonly imageDownload?: Maybe<(
    { readonly __typename?: 'Image' }
    & IImageFragment
  )> };

export type ISectionsQueryVariables = Exact<{ [key: string]: never; }>;


export type ISectionsQuery = { readonly __typename?: 'Query', readonly sections?: Maybe<ReadonlyArray<Maybe<(
    { readonly __typename?: 'Section' }
    & ISectionFragment
  )>>> };

export type ISectionCreateMutationVariables = Exact<{
  input: ISectionInput;
}>;


export type ISectionCreateMutation = { readonly __typename?: 'Mutation', readonly sectionCreate?: Maybe<(
    { readonly __typename?: 'Section' }
    & ISectionFragment
  )> };

export type ISectionUpdateMutationVariables = Exact<{
  id: Scalars['MongoID'];
  input: ISectionInput;
}>;


export type ISectionUpdateMutation = { readonly __typename?: 'Mutation', readonly sectionUpdate?: Maybe<(
    { readonly __typename?: 'Section' }
    & ISectionFragment
  )> };

export type ISectionPreviewMutationVariables = Exact<{
  text: Scalars['String'];
}>;


export type ISectionPreviewMutation = { readonly __typename?: 'Mutation', readonly sectionPreview?: Maybe<any> };

export type ISettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type ISettingsQuery = { readonly __typename?: 'Query', readonly settings?: Maybe<ReadonlyArray<Maybe<{ readonly __typename?: 'Setting', readonly key?: Maybe<string>, readonly value?: Maybe<any> }>>> };

export type ISettingUpdateMutationVariables = Exact<{
  input: ISettingInput;
}>;


export type ISettingUpdateMutation = { readonly __typename?: 'Mutation', readonly settingUpdate?: Maybe<{ readonly __typename?: 'Setting', readonly key?: Maybe<string>, readonly value?: Maybe<any> }> };

export type ISourceQueryVariables = Exact<{
  id: Scalars['MongoID'];
}>;


export type ISourceQuery = { readonly __typename?: 'Query', readonly source?: Maybe<(
    { readonly __typename?: 'Source' }
    & ISourceFragment
  )> };

export type ISourcesQueryVariables = Exact<{
  ignore_enabled?: Maybe<Scalars['Boolean']>;
}>;


export type ISourcesQuery = { readonly __typename?: 'Query', readonly sources?: Maybe<ReadonlyArray<Maybe<(
    { readonly __typename?: 'Source' }
    & ISourceFragment
  )>>> };

export type ISourcePreviewMutationVariables = Exact<{
  input: ISourceInput;
}>;


export type ISourcePreviewMutation = { readonly __typename?: 'Mutation', readonly sourcePreview?: Maybe<ReadonlyArray<Maybe<{ readonly __typename?: 'ScraperStory', readonly key?: Maybe<string>, readonly title?: Maybe<string>, readonly summary?: Maybe<string>, readonly date?: Maybe<any>, readonly categories?: Maybe<ReadonlyArray<Maybe<string>>>, readonly url?: Maybe<string>, readonly image?: Maybe<string> }>>> };

export type ISourceCreateMutationVariables = Exact<{
  input: ISourceInput;
}>;


export type ISourceCreateMutation = { readonly __typename?: 'Mutation', readonly sourceCreate?: Maybe<(
    { readonly __typename?: 'Source' }
    & ISourceFragment
  )> };

export type ISourceUpdateMutationVariables = Exact<{
  id: Scalars['MongoID'];
  input: ISourceInput;
}>;


export type ISourceUpdateMutation = { readonly __typename?: 'Mutation', readonly sourceUpdate?: Maybe<(
    { readonly __typename?: 'Source' }
    & ISourceFragment
  )> };

export type ISourceCrawlMutationVariables = Exact<{
  id: Scalars['MongoID'];
}>;


export type ISourceCrawlMutation = { readonly __typename?: 'Mutation', readonly sourceCrawl?: Maybe<(
    { readonly __typename?: 'Source' }
    & ISourceFragment
  )> };

export type IStatsBySourceQueryVariables = Exact<{
  date_lt?: Maybe<Scalars['Date']>;
  date_gt?: Maybe<Scalars['Date']>;
}>;


export type IStatsBySourceQuery = { readonly __typename?: 'Query', readonly statsBySource?: Maybe<ReadonlyArray<Maybe<{ readonly __typename?: 'StatsSource', readonly stories?: Maybe<number>, readonly source?: Maybe<(
      { readonly __typename?: 'Source' }
      & ISourceFragment
    )> }>>> };

export type IStatsBySourceLastStoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type IStatsBySourceLastStoriesQuery = { readonly __typename?: 'Query', readonly statsBySourceLastStories?: Maybe<ReadonlyArray<Maybe<{ readonly __typename?: 'StatsSource', readonly lastStory?: Maybe<{ readonly __typename?: 'Story', readonly _id: any, readonly title: string, readonly date?: Maybe<any> }>, readonly source?: Maybe<(
      { readonly __typename?: 'Source' }
      & ISourceFragment
    )> }>>> };

export type IStatsClicksByStoryQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  realtime?: Maybe<Scalars['Boolean']>;
  date_lt?: Maybe<Scalars['Date']>;
  date_gt?: Maybe<Scalars['Date']>;
}>;


export type IStatsClicksByStoryQuery = { readonly __typename?: 'Query', readonly statsClicksByStory?: Maybe<ReadonlyArray<Maybe<{ readonly __typename?: 'StatsClicksStory', readonly clicks?: Maybe<number>, readonly story?: Maybe<(
      { readonly __typename?: 'Story' }
      & IStoryFragment
    )> }>>> };

export type IStatsClicksBySourceQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  realtime?: Maybe<Scalars['Boolean']>;
  date_lt?: Maybe<Scalars['Date']>;
  date_gt?: Maybe<Scalars['Date']>;
}>;


export type IStatsClicksBySourceQuery = { readonly __typename?: 'Query', readonly statsClicksBySource?: Maybe<ReadonlyArray<Maybe<{ readonly __typename?: 'StatsClicksSource', readonly clicks?: Maybe<number>, readonly source?: Maybe<(
      { readonly __typename?: 'Source' }
      & ISourceFragment
    )> }>>> };

export type IStatsTimelineQueryVariables = Exact<{
  date_lt?: Maybe<Scalars['Date']>;
  date_gt?: Maybe<Scalars['Date']>;
  group_by_hours?: Maybe<Scalars['Boolean']>;
  sources_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  sources_nin?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
}>;


export type IStatsTimelineQuery = { readonly __typename?: 'Query', readonly statsTimeline?: Maybe<ReadonlyArray<Maybe<{ readonly __typename?: 'StatsTimelineItem', readonly date?: Maybe<any>, readonly count?: Maybe<number> }>>> };

export type IStoriesQueryVariables = Exact<{
  ids?: Maybe<ReadonlyArray<Maybe<Scalars['MongoID']>>>;
  search?: Maybe<Scalars['String']>;
  date_lt?: Maybe<Scalars['Date']>;
  date_gt?: Maybe<Scalars['Date']>;
  sources_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  sources_nin?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  categories_in?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  categories_nin?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  ignore_enabled?: Maybe<Scalars['Boolean']>;
  section?: Maybe<Scalars['String']>;
}>;


export type IStoriesQuery = { readonly __typename?: 'Query', readonly stories?: Maybe<ReadonlyArray<Maybe<(
    { readonly __typename?: 'Story' }
    & IStoryFragment
  )>>> };

export type IStoriesClassifySectionMutationVariables = Exact<{
  date_lt?: Maybe<Scalars['Date']>;
  date_gt?: Maybe<Scalars['Date']>;
}>;


export type IStoriesClassifySectionMutation = { readonly __typename?: 'Mutation', readonly storiesClassifySection?: Maybe<string> };

export type IStoryUpdateMutationVariables = Exact<{
  id: Scalars['MongoID'];
  input: IStoryInput;
}>;


export type IStoryUpdateMutation = { readonly __typename?: 'Mutation', readonly storyUpdate?: Maybe<(
    { readonly __typename?: 'Story' }
    & IStoryFragment
  )> };

export const StoryFragmentDoc = gql`
    fragment StoryFragment on Story {
  _id
  key
  url
  title
  summary
  type
  section
  categories {
    _id
    name
  }
  source {
    _id
    name
  }
  image {
    _id
    url
  }
  date
  enabled
}
    `;
export const CoverageFragmentDoc = gql`
    fragment CoverageFragment on Coverage {
  _id
  name
  auto
  keywords
  stories {
    ...StoryFragment
  }
  order
  enabled
  pinned
  updatedAt
  createdAt
}
    ${StoryFragmentDoc}`;
export const HeadlineFragmentDoc = gql`
    fragment HeadlineFragment on Headline {
  _id
  story {
    ...StoryFragment
  }
  order
  expireAt
}
    ${StoryFragmentDoc}`;
export const ImageFragmentDoc = gql`
    fragment ImageFragment on Image {
  _id
  path
  url
  mime
  ext
  originalUrl
}
    `;
export const SectionFragmentDoc = gql`
    fragment SectionFragment on Section {
  _id
  key
  terms
}
    `;
export const SourceFragmentDoc = gql`
    fragment SourceFragment on Source {
  _id
  key
  name
  description
  section
  interval
  contact
  url
  feed
  scraper
  scraperEnabled
  scraperRegex
  scraperSlice
  scraperSkipMeta
  scraperUseBrowser
  enabled
  image {
    _id
    url
  }
  imageStory {
    _id
    url
  }
  government
}
    `;
export const CoveragesDocument = gql`
    query Coverages($sources_in: [String], $sources_nin: [String], $updatedAt_lt: Date, $updatedAt_gt: Date, $pinned: Boolean, $ignore_enabled: Boolean) {
  coverages(sources_in: $sources_in, sources_nin: $sources_nin, updatedAt_lt: $updatedAt_lt, updatedAt_gt: $updatedAt_gt, pinned: $pinned, ignore_enabled: $ignore_enabled) {
    ...CoverageFragment
  }
}
    ${CoverageFragmentDoc}`;

/**
 * __useCoveragesQuery__
 *
 * To run a query within a React component, call `useCoveragesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoveragesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoveragesQuery({
 *   variables: {
 *      sources_in: // value for 'sources_in'
 *      sources_nin: // value for 'sources_nin'
 *      updatedAt_lt: // value for 'updatedAt_lt'
 *      updatedAt_gt: // value for 'updatedAt_gt'
 *      pinned: // value for 'pinned'
 *      ignore_enabled: // value for 'ignore_enabled'
 *   },
 * });
 */
export function useCoveragesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ICoveragesQuery, ICoveragesQueryVariables>) {
        return ApolloReactHooks.useQuery<ICoveragesQuery, ICoveragesQueryVariables>(CoveragesDocument, baseOptions);
      }
export function useCoveragesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ICoveragesQuery, ICoveragesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ICoveragesQuery, ICoveragesQueryVariables>(CoveragesDocument, baseOptions);
        }
export type CoveragesQueryHookResult = ReturnType<typeof useCoveragesQuery>;
export type CoveragesLazyQueryHookResult = ReturnType<typeof useCoveragesLazyQuery>;
export type CoveragesQueryResult = ApolloReactCommon.QueryResult<ICoveragesQuery, ICoveragesQueryVariables>;
export const CoverageCreateDocument = gql`
    mutation CoverageCreate($input: CoverageInput!) {
  coverageCreate(coverage: $input) {
    _id
  }
}
    `;
export type ICoverageCreateMutationFn = ApolloReactCommon.MutationFunction<ICoverageCreateMutation, ICoverageCreateMutationVariables>;

/**
 * __useCoverageCreateMutation__
 *
 * To run a mutation, you first call `useCoverageCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCoverageCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [coverageCreateMutation, { data, loading, error }] = useCoverageCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCoverageCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ICoverageCreateMutation, ICoverageCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<ICoverageCreateMutation, ICoverageCreateMutationVariables>(CoverageCreateDocument, baseOptions);
      }
export type CoverageCreateMutationHookResult = ReturnType<typeof useCoverageCreateMutation>;
export type CoverageCreateMutationResult = ApolloReactCommon.MutationResult<ICoverageCreateMutation>;
export type CoverageCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<ICoverageCreateMutation, ICoverageCreateMutationVariables>;
export const CoverageUpdateDocument = gql`
    mutation CoverageUpdate($id: MongoID!, $input: CoverageInput!) {
  coverageUpdate(id: $id, coverage: $input) {
    _id
  }
}
    `;
export type ICoverageUpdateMutationFn = ApolloReactCommon.MutationFunction<ICoverageUpdateMutation, ICoverageUpdateMutationVariables>;

/**
 * __useCoverageUpdateMutation__
 *
 * To run a mutation, you first call `useCoverageUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCoverageUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [coverageUpdateMutation, { data, loading, error }] = useCoverageUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCoverageUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ICoverageUpdateMutation, ICoverageUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<ICoverageUpdateMutation, ICoverageUpdateMutationVariables>(CoverageUpdateDocument, baseOptions);
      }
export type CoverageUpdateMutationHookResult = ReturnType<typeof useCoverageUpdateMutation>;
export type CoverageUpdateMutationResult = ApolloReactCommon.MutationResult<ICoverageUpdateMutation>;
export type CoverageUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<ICoverageUpdateMutation, ICoverageUpdateMutationVariables>;
export const HeadlinesDocument = gql`
    query Headlines($sources_in: [String], $sources_nin: [String], $ignore_enabled: Boolean, $ignore_expireAt: Boolean) {
  headlines(sources_in: $sources_in, sources_nin: $sources_nin, ignore_enabled: $ignore_enabled, ignore_expireAt: $ignore_expireAt) {
    ...HeadlineFragment
  }
}
    ${HeadlineFragmentDoc}`;

/**
 * __useHeadlinesQuery__
 *
 * To run a query within a React component, call `useHeadlinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useHeadlinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHeadlinesQuery({
 *   variables: {
 *      sources_in: // value for 'sources_in'
 *      sources_nin: // value for 'sources_nin'
 *      ignore_enabled: // value for 'ignore_enabled'
 *      ignore_expireAt: // value for 'ignore_expireAt'
 *   },
 * });
 */
export function useHeadlinesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IHeadlinesQuery, IHeadlinesQueryVariables>) {
        return ApolloReactHooks.useQuery<IHeadlinesQuery, IHeadlinesQueryVariables>(HeadlinesDocument, baseOptions);
      }
export function useHeadlinesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IHeadlinesQuery, IHeadlinesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IHeadlinesQuery, IHeadlinesQueryVariables>(HeadlinesDocument, baseOptions);
        }
export type HeadlinesQueryHookResult = ReturnType<typeof useHeadlinesQuery>;
export type HeadlinesLazyQueryHookResult = ReturnType<typeof useHeadlinesLazyQuery>;
export type HeadlinesQueryResult = ApolloReactCommon.QueryResult<IHeadlinesQuery, IHeadlinesQueryVariables>;
export const HeadlineCreateDocument = gql`
    mutation HeadlineCreate($notify: Boolean, $input: HeadlineInput!) {
  headlineCreate(notify: $notify, headline: $input) {
    _id
  }
}
    `;
export type IHeadlineCreateMutationFn = ApolloReactCommon.MutationFunction<IHeadlineCreateMutation, IHeadlineCreateMutationVariables>;

/**
 * __useHeadlineCreateMutation__
 *
 * To run a mutation, you first call `useHeadlineCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHeadlineCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [headlineCreateMutation, { data, loading, error }] = useHeadlineCreateMutation({
 *   variables: {
 *      notify: // value for 'notify'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHeadlineCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IHeadlineCreateMutation, IHeadlineCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<IHeadlineCreateMutation, IHeadlineCreateMutationVariables>(HeadlineCreateDocument, baseOptions);
      }
export type HeadlineCreateMutationHookResult = ReturnType<typeof useHeadlineCreateMutation>;
export type HeadlineCreateMutationResult = ApolloReactCommon.MutationResult<IHeadlineCreateMutation>;
export type HeadlineCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<IHeadlineCreateMutation, IHeadlineCreateMutationVariables>;
export const HeadlineUpdateDocument = gql`
    mutation HeadlineUpdate($id: MongoID!, $input: HeadlineInput!) {
  headlineUpdate(id: $id, headline: $input) {
    _id
  }
}
    `;
export type IHeadlineUpdateMutationFn = ApolloReactCommon.MutationFunction<IHeadlineUpdateMutation, IHeadlineUpdateMutationVariables>;

/**
 * __useHeadlineUpdateMutation__
 *
 * To run a mutation, you first call `useHeadlineUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHeadlineUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [headlineUpdateMutation, { data, loading, error }] = useHeadlineUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHeadlineUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IHeadlineUpdateMutation, IHeadlineUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<IHeadlineUpdateMutation, IHeadlineUpdateMutationVariables>(HeadlineUpdateDocument, baseOptions);
      }
export type HeadlineUpdateMutationHookResult = ReturnType<typeof useHeadlineUpdateMutation>;
export type HeadlineUpdateMutationResult = ApolloReactCommon.MutationResult<IHeadlineUpdateMutation>;
export type HeadlineUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<IHeadlineUpdateMutation, IHeadlineUpdateMutationVariables>;
export const ImageDownloadDocument = gql`
    mutation ImageDownload($url: String!) {
  imageDownload(url: $url) {
    ...ImageFragment
  }
}
    ${ImageFragmentDoc}`;
export type IImageDownloadMutationFn = ApolloReactCommon.MutationFunction<IImageDownloadMutation, IImageDownloadMutationVariables>;

/**
 * __useImageDownloadMutation__
 *
 * To run a mutation, you first call `useImageDownloadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImageDownloadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [imageDownloadMutation, { data, loading, error }] = useImageDownloadMutation({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useImageDownloadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IImageDownloadMutation, IImageDownloadMutationVariables>) {
        return ApolloReactHooks.useMutation<IImageDownloadMutation, IImageDownloadMutationVariables>(ImageDownloadDocument, baseOptions);
      }
export type ImageDownloadMutationHookResult = ReturnType<typeof useImageDownloadMutation>;
export type ImageDownloadMutationResult = ApolloReactCommon.MutationResult<IImageDownloadMutation>;
export type ImageDownloadMutationOptions = ApolloReactCommon.BaseMutationOptions<IImageDownloadMutation, IImageDownloadMutationVariables>;
export const SectionsDocument = gql`
    query Sections {
  sections {
    ...SectionFragment
  }
}
    ${SectionFragmentDoc}`;

/**
 * __useSectionsQuery__
 *
 * To run a query within a React component, call `useSectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSectionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSectionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ISectionsQuery, ISectionsQueryVariables>) {
        return ApolloReactHooks.useQuery<ISectionsQuery, ISectionsQueryVariables>(SectionsDocument, baseOptions);
      }
export function useSectionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ISectionsQuery, ISectionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ISectionsQuery, ISectionsQueryVariables>(SectionsDocument, baseOptions);
        }
export type SectionsQueryHookResult = ReturnType<typeof useSectionsQuery>;
export type SectionsLazyQueryHookResult = ReturnType<typeof useSectionsLazyQuery>;
export type SectionsQueryResult = ApolloReactCommon.QueryResult<ISectionsQuery, ISectionsQueryVariables>;
export const SectionCreateDocument = gql`
    mutation SectionCreate($input: SectionInput!) {
  sectionCreate(section: $input) {
    ...SectionFragment
  }
}
    ${SectionFragmentDoc}`;
export type ISectionCreateMutationFn = ApolloReactCommon.MutationFunction<ISectionCreateMutation, ISectionCreateMutationVariables>;

/**
 * __useSectionCreateMutation__
 *
 * To run a mutation, you first call `useSectionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSectionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sectionCreateMutation, { data, loading, error }] = useSectionCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSectionCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ISectionCreateMutation, ISectionCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<ISectionCreateMutation, ISectionCreateMutationVariables>(SectionCreateDocument, baseOptions);
      }
export type SectionCreateMutationHookResult = ReturnType<typeof useSectionCreateMutation>;
export type SectionCreateMutationResult = ApolloReactCommon.MutationResult<ISectionCreateMutation>;
export type SectionCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<ISectionCreateMutation, ISectionCreateMutationVariables>;
export const SectionUpdateDocument = gql`
    mutation SectionUpdate($id: MongoID!, $input: SectionInput!) {
  sectionUpdate(id: $id, section: $input) {
    ...SectionFragment
  }
}
    ${SectionFragmentDoc}`;
export type ISectionUpdateMutationFn = ApolloReactCommon.MutationFunction<ISectionUpdateMutation, ISectionUpdateMutationVariables>;

/**
 * __useSectionUpdateMutation__
 *
 * To run a mutation, you first call `useSectionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSectionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sectionUpdateMutation, { data, loading, error }] = useSectionUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSectionUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ISectionUpdateMutation, ISectionUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<ISectionUpdateMutation, ISectionUpdateMutationVariables>(SectionUpdateDocument, baseOptions);
      }
export type SectionUpdateMutationHookResult = ReturnType<typeof useSectionUpdateMutation>;
export type SectionUpdateMutationResult = ApolloReactCommon.MutationResult<ISectionUpdateMutation>;
export type SectionUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<ISectionUpdateMutation, ISectionUpdateMutationVariables>;
export const SectionPreviewDocument = gql`
    mutation SectionPreview($text: String!) {
  sectionPreview(text: $text)
}
    `;
export type ISectionPreviewMutationFn = ApolloReactCommon.MutationFunction<ISectionPreviewMutation, ISectionPreviewMutationVariables>;

/**
 * __useSectionPreviewMutation__
 *
 * To run a mutation, you first call `useSectionPreviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSectionPreviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sectionPreviewMutation, { data, loading, error }] = useSectionPreviewMutation({
 *   variables: {
 *      text: // value for 'text'
 *   },
 * });
 */
export function useSectionPreviewMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ISectionPreviewMutation, ISectionPreviewMutationVariables>) {
        return ApolloReactHooks.useMutation<ISectionPreviewMutation, ISectionPreviewMutationVariables>(SectionPreviewDocument, baseOptions);
      }
export type SectionPreviewMutationHookResult = ReturnType<typeof useSectionPreviewMutation>;
export type SectionPreviewMutationResult = ApolloReactCommon.MutationResult<ISectionPreviewMutation>;
export type SectionPreviewMutationOptions = ApolloReactCommon.BaseMutationOptions<ISectionPreviewMutation, ISectionPreviewMutationVariables>;
export const SettingsDocument = gql`
    query Settings {
  settings {
    key
    value
  }
}
    `;

/**
 * __useSettingsQuery__
 *
 * To run a query within a React component, call `useSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSettingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ISettingsQuery, ISettingsQueryVariables>) {
        return ApolloReactHooks.useQuery<ISettingsQuery, ISettingsQueryVariables>(SettingsDocument, baseOptions);
      }
export function useSettingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ISettingsQuery, ISettingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ISettingsQuery, ISettingsQueryVariables>(SettingsDocument, baseOptions);
        }
export type SettingsQueryHookResult = ReturnType<typeof useSettingsQuery>;
export type SettingsLazyQueryHookResult = ReturnType<typeof useSettingsLazyQuery>;
export type SettingsQueryResult = ApolloReactCommon.QueryResult<ISettingsQuery, ISettingsQueryVariables>;
export const SettingUpdateDocument = gql`
    mutation SettingUpdate($input: SettingInput!) {
  settingUpdate(setting: $input) {
    key
    value
  }
}
    `;
export type ISettingUpdateMutationFn = ApolloReactCommon.MutationFunction<ISettingUpdateMutation, ISettingUpdateMutationVariables>;

/**
 * __useSettingUpdateMutation__
 *
 * To run a mutation, you first call `useSettingUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingUpdateMutation, { data, loading, error }] = useSettingUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSettingUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ISettingUpdateMutation, ISettingUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<ISettingUpdateMutation, ISettingUpdateMutationVariables>(SettingUpdateDocument, baseOptions);
      }
export type SettingUpdateMutationHookResult = ReturnType<typeof useSettingUpdateMutation>;
export type SettingUpdateMutationResult = ApolloReactCommon.MutationResult<ISettingUpdateMutation>;
export type SettingUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<ISettingUpdateMutation, ISettingUpdateMutationVariables>;
export const SourceDocument = gql`
    query Source($id: MongoID!) {
  source(id: $id) {
    ...SourceFragment
  }
}
    ${SourceFragmentDoc}`;

/**
 * __useSourceQuery__
 *
 * To run a query within a React component, call `useSourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useSourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSourceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSourceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ISourceQuery, ISourceQueryVariables>) {
        return ApolloReactHooks.useQuery<ISourceQuery, ISourceQueryVariables>(SourceDocument, baseOptions);
      }
export function useSourceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ISourceQuery, ISourceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ISourceQuery, ISourceQueryVariables>(SourceDocument, baseOptions);
        }
export type SourceQueryHookResult = ReturnType<typeof useSourceQuery>;
export type SourceLazyQueryHookResult = ReturnType<typeof useSourceLazyQuery>;
export type SourceQueryResult = ApolloReactCommon.QueryResult<ISourceQuery, ISourceQueryVariables>;
export const SourcesDocument = gql`
    query Sources($ignore_enabled: Boolean) {
  sources(ignore_enabled: $ignore_enabled) {
    ...SourceFragment
  }
}
    ${SourceFragmentDoc}`;

/**
 * __useSourcesQuery__
 *
 * To run a query within a React component, call `useSourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSourcesQuery({
 *   variables: {
 *      ignore_enabled: // value for 'ignore_enabled'
 *   },
 * });
 */
export function useSourcesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ISourcesQuery, ISourcesQueryVariables>) {
        return ApolloReactHooks.useQuery<ISourcesQuery, ISourcesQueryVariables>(SourcesDocument, baseOptions);
      }
export function useSourcesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ISourcesQuery, ISourcesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ISourcesQuery, ISourcesQueryVariables>(SourcesDocument, baseOptions);
        }
export type SourcesQueryHookResult = ReturnType<typeof useSourcesQuery>;
export type SourcesLazyQueryHookResult = ReturnType<typeof useSourcesLazyQuery>;
export type SourcesQueryResult = ApolloReactCommon.QueryResult<ISourcesQuery, ISourcesQueryVariables>;
export const SourcePreviewDocument = gql`
    mutation SourcePreview($input: SourceInput!) {
  sourcePreview(source: $input) {
    key
    title
    summary
    date
    categories
    url
    image
  }
}
    `;
export type ISourcePreviewMutationFn = ApolloReactCommon.MutationFunction<ISourcePreviewMutation, ISourcePreviewMutationVariables>;

/**
 * __useSourcePreviewMutation__
 *
 * To run a mutation, you first call `useSourcePreviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSourcePreviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sourcePreviewMutation, { data, loading, error }] = useSourcePreviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSourcePreviewMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ISourcePreviewMutation, ISourcePreviewMutationVariables>) {
        return ApolloReactHooks.useMutation<ISourcePreviewMutation, ISourcePreviewMutationVariables>(SourcePreviewDocument, baseOptions);
      }
export type SourcePreviewMutationHookResult = ReturnType<typeof useSourcePreviewMutation>;
export type SourcePreviewMutationResult = ApolloReactCommon.MutationResult<ISourcePreviewMutation>;
export type SourcePreviewMutationOptions = ApolloReactCommon.BaseMutationOptions<ISourcePreviewMutation, ISourcePreviewMutationVariables>;
export const SourceCreateDocument = gql`
    mutation SourceCreate($input: SourceInput!) {
  sourceCreate(source: $input) {
    ...SourceFragment
  }
}
    ${SourceFragmentDoc}`;
export type ISourceCreateMutationFn = ApolloReactCommon.MutationFunction<ISourceCreateMutation, ISourceCreateMutationVariables>;

/**
 * __useSourceCreateMutation__
 *
 * To run a mutation, you first call `useSourceCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSourceCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sourceCreateMutation, { data, loading, error }] = useSourceCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSourceCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ISourceCreateMutation, ISourceCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<ISourceCreateMutation, ISourceCreateMutationVariables>(SourceCreateDocument, baseOptions);
      }
export type SourceCreateMutationHookResult = ReturnType<typeof useSourceCreateMutation>;
export type SourceCreateMutationResult = ApolloReactCommon.MutationResult<ISourceCreateMutation>;
export type SourceCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<ISourceCreateMutation, ISourceCreateMutationVariables>;
export const SourceUpdateDocument = gql`
    mutation SourceUpdate($id: MongoID!, $input: SourceInput!) {
  sourceUpdate(id: $id, source: $input) {
    ...SourceFragment
  }
}
    ${SourceFragmentDoc}`;
export type ISourceUpdateMutationFn = ApolloReactCommon.MutationFunction<ISourceUpdateMutation, ISourceUpdateMutationVariables>;

/**
 * __useSourceUpdateMutation__
 *
 * To run a mutation, you first call `useSourceUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSourceUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sourceUpdateMutation, { data, loading, error }] = useSourceUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSourceUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ISourceUpdateMutation, ISourceUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<ISourceUpdateMutation, ISourceUpdateMutationVariables>(SourceUpdateDocument, baseOptions);
      }
export type SourceUpdateMutationHookResult = ReturnType<typeof useSourceUpdateMutation>;
export type SourceUpdateMutationResult = ApolloReactCommon.MutationResult<ISourceUpdateMutation>;
export type SourceUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<ISourceUpdateMutation, ISourceUpdateMutationVariables>;
export const SourceCrawlDocument = gql`
    mutation SourceCrawl($id: MongoID!) {
  sourceCrawl(id: $id) {
    ...SourceFragment
  }
}
    ${SourceFragmentDoc}`;
export type ISourceCrawlMutationFn = ApolloReactCommon.MutationFunction<ISourceCrawlMutation, ISourceCrawlMutationVariables>;

/**
 * __useSourceCrawlMutation__
 *
 * To run a mutation, you first call `useSourceCrawlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSourceCrawlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sourceCrawlMutation, { data, loading, error }] = useSourceCrawlMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSourceCrawlMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ISourceCrawlMutation, ISourceCrawlMutationVariables>) {
        return ApolloReactHooks.useMutation<ISourceCrawlMutation, ISourceCrawlMutationVariables>(SourceCrawlDocument, baseOptions);
      }
export type SourceCrawlMutationHookResult = ReturnType<typeof useSourceCrawlMutation>;
export type SourceCrawlMutationResult = ApolloReactCommon.MutationResult<ISourceCrawlMutation>;
export type SourceCrawlMutationOptions = ApolloReactCommon.BaseMutationOptions<ISourceCrawlMutation, ISourceCrawlMutationVariables>;
export const StatsBySourceDocument = gql`
    query StatsBySource($date_lt: Date, $date_gt: Date) {
  statsBySource(date_lt: $date_lt, date_gt: $date_gt) {
    stories
    source {
      ...SourceFragment
    }
  }
}
    ${SourceFragmentDoc}`;

/**
 * __useStatsBySourceQuery__
 *
 * To run a query within a React component, call `useStatsBySourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatsBySourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatsBySourceQuery({
 *   variables: {
 *      date_lt: // value for 'date_lt'
 *      date_gt: // value for 'date_gt'
 *   },
 * });
 */
export function useStatsBySourceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IStatsBySourceQuery, IStatsBySourceQueryVariables>) {
        return ApolloReactHooks.useQuery<IStatsBySourceQuery, IStatsBySourceQueryVariables>(StatsBySourceDocument, baseOptions);
      }
export function useStatsBySourceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IStatsBySourceQuery, IStatsBySourceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IStatsBySourceQuery, IStatsBySourceQueryVariables>(StatsBySourceDocument, baseOptions);
        }
export type StatsBySourceQueryHookResult = ReturnType<typeof useStatsBySourceQuery>;
export type StatsBySourceLazyQueryHookResult = ReturnType<typeof useStatsBySourceLazyQuery>;
export type StatsBySourceQueryResult = ApolloReactCommon.QueryResult<IStatsBySourceQuery, IStatsBySourceQueryVariables>;
export const StatsBySourceLastStoriesDocument = gql`
    query StatsBySourceLastStories {
  statsBySourceLastStories {
    lastStory {
      _id
      title
      date
    }
    source {
      ...SourceFragment
    }
  }
}
    ${SourceFragmentDoc}`;

/**
 * __useStatsBySourceLastStoriesQuery__
 *
 * To run a query within a React component, call `useStatsBySourceLastStoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatsBySourceLastStoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatsBySourceLastStoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useStatsBySourceLastStoriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IStatsBySourceLastStoriesQuery, IStatsBySourceLastStoriesQueryVariables>) {
        return ApolloReactHooks.useQuery<IStatsBySourceLastStoriesQuery, IStatsBySourceLastStoriesQueryVariables>(StatsBySourceLastStoriesDocument, baseOptions);
      }
export function useStatsBySourceLastStoriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IStatsBySourceLastStoriesQuery, IStatsBySourceLastStoriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IStatsBySourceLastStoriesQuery, IStatsBySourceLastStoriesQueryVariables>(StatsBySourceLastStoriesDocument, baseOptions);
        }
export type StatsBySourceLastStoriesQueryHookResult = ReturnType<typeof useStatsBySourceLastStoriesQuery>;
export type StatsBySourceLastStoriesLazyQueryHookResult = ReturnType<typeof useStatsBySourceLastStoriesLazyQuery>;
export type StatsBySourceLastStoriesQueryResult = ApolloReactCommon.QueryResult<IStatsBySourceLastStoriesQuery, IStatsBySourceLastStoriesQueryVariables>;
export const StatsClicksByStoryDocument = gql`
    query StatsClicksByStory($limit: Int, $realtime: Boolean, $date_lt: Date, $date_gt: Date) {
  statsClicksByStory(limit: $limit, realtime: $realtime, date_gt: $date_gt, date_lt: $date_lt) {
    story {
      ...StoryFragment
    }
    clicks
  }
}
    ${StoryFragmentDoc}`;

/**
 * __useStatsClicksByStoryQuery__
 *
 * To run a query within a React component, call `useStatsClicksByStoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatsClicksByStoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatsClicksByStoryQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      realtime: // value for 'realtime'
 *      date_lt: // value for 'date_lt'
 *      date_gt: // value for 'date_gt'
 *   },
 * });
 */
export function useStatsClicksByStoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IStatsClicksByStoryQuery, IStatsClicksByStoryQueryVariables>) {
        return ApolloReactHooks.useQuery<IStatsClicksByStoryQuery, IStatsClicksByStoryQueryVariables>(StatsClicksByStoryDocument, baseOptions);
      }
export function useStatsClicksByStoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IStatsClicksByStoryQuery, IStatsClicksByStoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IStatsClicksByStoryQuery, IStatsClicksByStoryQueryVariables>(StatsClicksByStoryDocument, baseOptions);
        }
export type StatsClicksByStoryQueryHookResult = ReturnType<typeof useStatsClicksByStoryQuery>;
export type StatsClicksByStoryLazyQueryHookResult = ReturnType<typeof useStatsClicksByStoryLazyQuery>;
export type StatsClicksByStoryQueryResult = ApolloReactCommon.QueryResult<IStatsClicksByStoryQuery, IStatsClicksByStoryQueryVariables>;
export const StatsClicksBySourceDocument = gql`
    query StatsClicksBySource($limit: Int, $realtime: Boolean, $date_lt: Date, $date_gt: Date) {
  statsClicksBySource(limit: $limit, realtime: $realtime, date_gt: $date_gt, date_lt: $date_lt) {
    source {
      ...SourceFragment
    }
    clicks
  }
}
    ${SourceFragmentDoc}`;

/**
 * __useStatsClicksBySourceQuery__
 *
 * To run a query within a React component, call `useStatsClicksBySourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatsClicksBySourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatsClicksBySourceQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      realtime: // value for 'realtime'
 *      date_lt: // value for 'date_lt'
 *      date_gt: // value for 'date_gt'
 *   },
 * });
 */
export function useStatsClicksBySourceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IStatsClicksBySourceQuery, IStatsClicksBySourceQueryVariables>) {
        return ApolloReactHooks.useQuery<IStatsClicksBySourceQuery, IStatsClicksBySourceQueryVariables>(StatsClicksBySourceDocument, baseOptions);
      }
export function useStatsClicksBySourceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IStatsClicksBySourceQuery, IStatsClicksBySourceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IStatsClicksBySourceQuery, IStatsClicksBySourceQueryVariables>(StatsClicksBySourceDocument, baseOptions);
        }
export type StatsClicksBySourceQueryHookResult = ReturnType<typeof useStatsClicksBySourceQuery>;
export type StatsClicksBySourceLazyQueryHookResult = ReturnType<typeof useStatsClicksBySourceLazyQuery>;
export type StatsClicksBySourceQueryResult = ApolloReactCommon.QueryResult<IStatsClicksBySourceQuery, IStatsClicksBySourceQueryVariables>;
export const StatsTimelineDocument = gql`
    query StatsTimeline($date_lt: Date, $date_gt: Date, $group_by_hours: Boolean, $sources_in: [String], $sources_nin: [String]) {
  statsTimeline(date_lt: $date_lt, date_gt: $date_gt, group_by_hours: $group_by_hours, sources_in: $sources_in, sources_nin: $sources_nin) {
    date
    count
  }
}
    `;

/**
 * __useStatsTimelineQuery__
 *
 * To run a query within a React component, call `useStatsTimelineQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatsTimelineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatsTimelineQuery({
 *   variables: {
 *      date_lt: // value for 'date_lt'
 *      date_gt: // value for 'date_gt'
 *      group_by_hours: // value for 'group_by_hours'
 *      sources_in: // value for 'sources_in'
 *      sources_nin: // value for 'sources_nin'
 *   },
 * });
 */
export function useStatsTimelineQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IStatsTimelineQuery, IStatsTimelineQueryVariables>) {
        return ApolloReactHooks.useQuery<IStatsTimelineQuery, IStatsTimelineQueryVariables>(StatsTimelineDocument, baseOptions);
      }
export function useStatsTimelineLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IStatsTimelineQuery, IStatsTimelineQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IStatsTimelineQuery, IStatsTimelineQueryVariables>(StatsTimelineDocument, baseOptions);
        }
export type StatsTimelineQueryHookResult = ReturnType<typeof useStatsTimelineQuery>;
export type StatsTimelineLazyQueryHookResult = ReturnType<typeof useStatsTimelineLazyQuery>;
export type StatsTimelineQueryResult = ApolloReactCommon.QueryResult<IStatsTimelineQuery, IStatsTimelineQueryVariables>;
export const StoriesDocument = gql`
    query Stories($ids: [MongoID], $search: String, $date_lt: Date, $date_gt: Date, $sources_in: [String], $sources_nin: [String], $categories_in: [String], $categories_nin: [String], $ignore_enabled: Boolean, $section: String) {
  stories(ids: $ids, search: $search, date_lt: $date_lt, date_gt: $date_gt, sources_in: $sources_in, sources_nin: $sources_nin, categories_in: $categories_in, categories_nin: $categories_nin, ignore_enabled: $ignore_enabled, section: $section) {
    ...StoryFragment
  }
}
    ${StoryFragmentDoc}`;

/**
 * __useStoriesQuery__
 *
 * To run a query within a React component, call `useStoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoriesQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      search: // value for 'search'
 *      date_lt: // value for 'date_lt'
 *      date_gt: // value for 'date_gt'
 *      sources_in: // value for 'sources_in'
 *      sources_nin: // value for 'sources_nin'
 *      categories_in: // value for 'categories_in'
 *      categories_nin: // value for 'categories_nin'
 *      ignore_enabled: // value for 'ignore_enabled'
 *      section: // value for 'section'
 *   },
 * });
 */
export function useStoriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IStoriesQuery, IStoriesQueryVariables>) {
        return ApolloReactHooks.useQuery<IStoriesQuery, IStoriesQueryVariables>(StoriesDocument, baseOptions);
      }
export function useStoriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IStoriesQuery, IStoriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IStoriesQuery, IStoriesQueryVariables>(StoriesDocument, baseOptions);
        }
export type StoriesQueryHookResult = ReturnType<typeof useStoriesQuery>;
export type StoriesLazyQueryHookResult = ReturnType<typeof useStoriesLazyQuery>;
export type StoriesQueryResult = ApolloReactCommon.QueryResult<IStoriesQuery, IStoriesQueryVariables>;
export const StoriesClassifySectionDocument = gql`
    mutation StoriesClassifySection($date_lt: Date, $date_gt: Date) {
  storiesClassifySection(date_lt: $date_lt, date_gt: $date_gt)
}
    `;
export type IStoriesClassifySectionMutationFn = ApolloReactCommon.MutationFunction<IStoriesClassifySectionMutation, IStoriesClassifySectionMutationVariables>;

/**
 * __useStoriesClassifySectionMutation__
 *
 * To run a mutation, you first call `useStoriesClassifySectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoriesClassifySectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storiesClassifySectionMutation, { data, loading, error }] = useStoriesClassifySectionMutation({
 *   variables: {
 *      date_lt: // value for 'date_lt'
 *      date_gt: // value for 'date_gt'
 *   },
 * });
 */
export function useStoriesClassifySectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IStoriesClassifySectionMutation, IStoriesClassifySectionMutationVariables>) {
        return ApolloReactHooks.useMutation<IStoriesClassifySectionMutation, IStoriesClassifySectionMutationVariables>(StoriesClassifySectionDocument, baseOptions);
      }
export type StoriesClassifySectionMutationHookResult = ReturnType<typeof useStoriesClassifySectionMutation>;
export type StoriesClassifySectionMutationResult = ApolloReactCommon.MutationResult<IStoriesClassifySectionMutation>;
export type StoriesClassifySectionMutationOptions = ApolloReactCommon.BaseMutationOptions<IStoriesClassifySectionMutation, IStoriesClassifySectionMutationVariables>;
export const StoryUpdateDocument = gql`
    mutation StoryUpdate($id: MongoID!, $input: StoryInput!) {
  storyUpdate(id: $id, story: $input) {
    ...StoryFragment
  }
}
    ${StoryFragmentDoc}`;
export type IStoryUpdateMutationFn = ApolloReactCommon.MutationFunction<IStoryUpdateMutation, IStoryUpdateMutationVariables>;

/**
 * __useStoryUpdateMutation__
 *
 * To run a mutation, you first call `useStoryUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoryUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storyUpdateMutation, { data, loading, error }] = useStoryUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStoryUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IStoryUpdateMutation, IStoryUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<IStoryUpdateMutation, IStoryUpdateMutationVariables>(StoryUpdateDocument, baseOptions);
      }
export type StoryUpdateMutationHookResult = ReturnType<typeof useStoryUpdateMutation>;
export type StoryUpdateMutationResult = ApolloReactCommon.MutationResult<IStoryUpdateMutation>;
export type StoryUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<IStoryUpdateMutation, IStoryUpdateMutationVariables>;