import React, { CSSProperties, useState, useEffect } from "react";
import {
    IonModal,
    IonRow,
    IonCol,
    IonButton,
    IonIcon,
    IonList,
    IonImg,
    IonItem,
    IonLabel,
    IonInput,
    IonSpinner,
} from "@ionic/react";
import { close } from "ionicons/icons";
import { IImage, useImageDownloadMutation } from "../types/graphql";

interface IImageEditorProps {
    onClose: Function;
    onSave: Function;
    open: boolean;
    style?: CSSProperties;
    size?: number;
}

const ImageEditor: React.FC<IImageEditorProps> = ({
    onClose,
    onSave,
    open,
    style = {},
    size = 64,
}) => {
    const [imageUpdate, setImageUpdate] = useState<IImage>({} as IImage);
    const [imagePreview, setImagePreview] = useState<string>("");
    const [downloadImage, { loading }] = useImageDownloadMutation();
    useEffect(() => {
        setImagePreview("");
        setImageUpdate({} as IImage);
    }, [open]);
    return (
        <IonModal
            isOpen={open}
            backdropDismiss={false}
            onDidDismiss={() => onClose()}
            cssClass="ion-modal-scrollable"
        >
            <IonRow className="ion-align-items-center ion-padding-start">
                <IonCol>
                    <h5>Editar imagen</h5>
                </IonCol>
                <IonCol className="ion-text-end">
                    <IonButton fill="clear" onClick={onClose as any}>
                        <IonIcon slot="icon-only" icon={close} />
                    </IonButton>
                </IonCol>
            </IonRow>
            <IonList style={{ padding: 32, textAlign: "center" }}>
                {imageUpdate?.url ? (
                    <IonImg style={{ width: size, margin: "auto" }} src={imageUpdate.url} />
                ) : (
                    <div
                        style={{
                            margin: "auto",
                            width: size,
                            height: size,
                            backgroundColor: "#999",
                        }}
                    />
                )}
                <IonItem class="ion-margin-top">
                    <IonLabel position="stacked">Direccion</IonLabel>
                    <IonInput
                        placeholder="Direccion"
                        type="url"
                        value={imagePreview}
                        onIonChange={(e) => setImagePreview(e.detail.value!)}
                    />
                </IonItem>
                <div style={{ margin: "32px auto 32px" }}>
                    <IonButton
                        fill="outline"
                        size="small"
                        onClick={() => {
                            setImageUpdate({ ...imageUpdate, url: imagePreview });
                        }}
                    >
                        Preview
                    </IonButton>
                </div>
            </IonList>
            <div className="ion-padding ion-text-end">
                {loading ? (
                    <IonSpinner style={{ height: 40 }} />
                ) : (
                    <IonButton
                        disabled={!imageUpdate.url}
                        onClick={async () => {
                            const result = await downloadImage({
                                variables: { url: imageUpdate.url as string },
                            });
                            if (result.data?.imageDownload) {
                                onSave(result.data.imageDownload);
                            }
                        }}
                    >
                        Salvar
                    </IonButton>
                )}
            </div>
        </IonModal>
    );
};

export default ImageEditor;
