import React from "react";
import { IonBadge } from "@ionic/react";
import { ICategory } from "../types/graphql";

interface ICategoriesBadgesProps {
    categories: ICategory[];
}

const CategoriesBadges: React.FC<ICategoriesBadgesProps> = ({ categories }) => {
    return (
        <>
            {categories.map((category: ICategory) => <IonBadge style={{ marginRight: 4 }} color="medium" key={category._id}>{category.name}</IonBadge>)}
        </>
    );
};

export default CategoriesBadges;
