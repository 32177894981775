import moment from "moment";
import React, { useState } from "react";
import {
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonCol,
    IonRow,
    IonList,
    IonItem,
    IonLabel,
    IonText,
    IonSegment,
    IonSegmentButton,
    IonButton,
    IonSpinner,
    IonIcon,
    IonThumbnail,
} from "@ionic/react";
import { reload } from "ionicons/icons";
import { useStatsClicksByStoryQuery } from "../types/graphql";

const StatsClicksByStory: React.FC = () => {
    const [date] = useState(new Date());
    const [limit, setLimit] = useState(5);
    const [interval, setInterval] = useState("day");
    const { data, loading, refetch } = useStatsClicksByStoryQuery({
        variables: interval !== 'day' ? {
            date_gt: moment(date).subtract(1, interval as any),
            date_lt: moment(date),
        } : {
                realtime: true
            },
    });
    return (
        <IonCard>
            {loading ? (
                <IonCardContent class="card-content-loading-large">
                    <IonSpinner />
                </IonCardContent>
            ) : (
                    <>
                        <IonCardHeader>
                            <IonCardTitle>
                                <IonRow className="ion-margin-bottom">
                                    <IonCol className="ion-no-padding ion-align-self-center">
                                        Clicks por Historia
                                </IonCol>
                                    <IonCol size="auto" className="ion-no-padding ion-text-end">
                                        {loading ? (
                                            <IonSpinner />
                                        ) : (
                                                <IonButton
                                                    fill="clear"
                                                    size="small"
                                                    onClick={() => refetch()}
                                                >
                                                    <IonIcon slot="icon-only" icon={reload} />
                                                </IonButton>
                                            )}
                                    </IonCol>
                                </IonRow>
                                <IonSegment
                                    value={interval}
                                    onIonChange={(e) => setInterval(e.detail.value as string)}
                                >
                                    <IonSegmentButton value="day">
                                        <IonLabel>Hoy</IonLabel>
                                    </IonSegmentButton>
                                    <IonSegmentButton value="week">
                                        <IonLabel>Última Semana</IonLabel>
                                    </IonSegmentButton>
                                    <IonSegmentButton value="month">
                                        <IonLabel>Último Mes</IonLabel>
                                    </IonSegmentButton>
                                </IonSegment>
                            </IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent>
                            <IonList>
                                {data?.statsClicksByStory
                                    ?.map(({ clicks, story }: any) => (
                                        <IonItem
                                            key={story._id}
                                            className="transparent"
                                            lines="none"
                                            detail={false}
                                        >
                                            <IonThumbnail slot="start">
                                                {story?.image && (
                                                    <img
                                                        src={story?.image?.url as string}
                                                        alt={story?.title as string}
                                                    />
                                                )}
                                            </IonThumbnail>
                                            <IonLabel className="ion-text-wrap">
                                                <IonText>
                                                    <h3>{story?.source?.name}</h3>
                                                </IonText>
                                                <p>
                                                    <a
                                                        href={story?.url}
                                                        style={{ textDecoration: "none" }}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {story?.title}
                                                    </a>
                                                </p>
                                                <IonText>
                                                    <p>{moment(story?.date).format("LLL")}</p>
                                                </IonText>
                                            </IonLabel>
                                            <IonButton fill="clear" slot="end">
                                                <h3>{clicks}</h3>
                                            </IonButton>
                                        </IonItem>
                                    ))
                                    .slice(0, limit)}
                                {data?.statsClicksByStory && data?.statsClicksByStory?.length > 5 && (
                                    <div className="ion-margin-top ion-text-center">
                                        <IonButton
                                            color="dark"
                                            fill="outline"
                                            onClick={() => (limit === 5 ? setLimit(100) : setLimit(5))}
                                        >
                                            Mostrar {limit === 5 ? "Todas" : "Menos"}
                                        </IonButton>
                                    </div>
                                )}
                            </IonList>
                        </IonCardContent>
                    </>
                )}
        </IonCard>
    );
};

export default StatsClicksByStory;
